import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import './payment.css';
import Flower from './flower';

import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalButtons,
  PayPalCardFieldsForm,
} from "@paypal/react-paypal-js";

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function App() {

  const location = useLocation();
  const [order, setOrder] = useState(location?.state?.sendData || {});
  console.log(order);

  useEffect(()=> {
    const order = location?.state?.sendData;
    setOrder(order);
  },[location]);

  const [isPaying, setIsPaying] = useState(false);
  const [message, setMessage] = useState("");
  const initialOptions = {
    "client-id": "AWoFHae2vK3vhd5clRcGQKJHV-6ribXaFL89c3do_Rgc3WsLN-xnIj8osTvwPELBWVjdCfb43CNXMMa6",
    // "enable-funding": "venmo",
    // "buyer-country": "US",
    currency: "USD",
    components: "buttons,card-fields",
  };

  const [billingAddress, setBillingAddress] = useState({
    addressLine1: order?.customerData?.address1 || "",
    addressLine2: order?.customerData?.address2 || "",
    adminArea1: order?.customerData?.city || "",
    adminArea2: order?.customerData?.state || "",
    countryCode: "US",
    postalCode: order?.customerData?.zip || "",
  });

  function handleBillingAddressChange(field, value) {
    setBillingAddress((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  async function createOrder() {
    try {
      const response = await fetch("https://api.vivekflowers.com/api/paypal", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
          serverDetails: {
            cart: [
              {
                sku: "1blwyeo8",
                quantity: 2,
              },
            ],
            card: {
              attributes: {
                verification: {
                  method: "SCA_ALWAYS",
                },
              },
            },
          },
          orderDetails: {
            amount: order?.grandTotal, // Ensure you're passing the correct order data here
            orderData: order,
          },
        }),
      });

      const orderData = await response.json();

      console.log('create',orderData);

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      return `Could not initiate PayPal Checkout...${error}`;
    }
  }

  async function onApprove(data, actions) {
    try {
      const response = await fetch(`https://api.vivekflowers.com/api/paypal/${data.orderID}/capture`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const orderData = await response.json();
      console.log('capture',orderData);

      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const transaction =
        orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
        orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
      const errorDetail = orderData?.details?.[0];

      if (errorDetail || !transaction || transaction.status === "DECLINED") {
        // (2) Other non-recoverable errors -> Show a failure message
        let errorMessage;
        if (transaction) {
          errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
        } else if (errorDetail) {
          errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
        } else {
          errorMessage = JSON.stringify(orderData);
        }

        throw new Error(errorMessage);
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');
        console.log(
          "Capture results",
          orderData,
          JSON.stringify(orderData, null, 2)
        );
       
        if(orderData){
          window.location = `payment-process?token=${orderData.id}`;
        }
        
        //return `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`;
      }
    } catch (error) {
      return `Sorry, your transaction could not be processed...${error}`;
    }
  }

  function onError(error) {
    // Do something with the error from the SDK
  }

  return (

    <>
    <div className="checkout_page">

      <Header />

      {/* breadcrumb */}
        <div class="sticky-header-next-sec  ec-breadcrumb section-space-mb">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row ec_breadcrumb_inner">
                            <div class="col-md-6 col-sm-12">
                                <h2 class="ec-breadcrumb-title">Payment</h2>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <ul class="ec-breadcrumb-list">
                                    <li class="ec-breadcrumb-item"><a href="/">Home</a></li>
                                    <li class="ec-breadcrumb-item"><a href="/checkout">Checkout</a></li>
                                    <li class="ec-breadcrumb-item active">Pay Now</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="payment">
          <div class="payment-container">
            <h2 style={{fontWeight: 600}}>Pay with Card</h2>
            <div class="card-icons">
              <img src="assets/images/icons/payment1.png" alt="Visa" />
              <img src="assets/images/icons/payment2.png" alt="MasterCard" />
              <img src="assets/images/icons/payment3.png" alt="American Express" />
              <img src="assets/images/icons/payment4.png" alt="Maestro" />
              <img src="assets/images/icons/payment5.png" alt="Visa" />
              <img src="assets/images/icons/payment6.png" alt="PayPal" />
            </div>
            
            {order?.grandTotal > 0 ?
              <>
                <PayPalScriptProvider options={initialOptions}>

                    <PayPalCardFieldsProvider
                      createOrder={createOrder}
                      onApprove={async (data) => setMessage(await onApprove(data))}
                    >

                      <PayPalCardFieldsForm />

                      <hr />
                      <h5 class="mt-4 mb-3 text-center">Billing Details</h5>

                      <div class="form-group">
                        <label for="card-billing-address-line-1">Address Line 1</label>
                        <input type="text" id="card-billing-address-line-1" name="card-billing-address-line-1"
                          value={billingAddress.addressLine1}
                          onChange={(e) =>
                            handleBillingAddressChange("addressLine1", e.target.value)
                          } 
                          required 
                        />
                      </div>

                      <div class="form-group">
                        <label for="card-billing-address-line-2">Address Line 2</label>
                        <input type="text" id="card-billing-address-line-2" name="card-billing-address-line-2"
                          value={billingAddress.addressLine2}
                          onChange={(e) =>
                            handleBillingAddressChange("addressLine1", e.target.value)
                          } 
                          required 
                        />
                      </div>

                      <div class="form-group-2">
                        <div class="form-group">
                            <label for="expiry-date">Area</label>
                            <input type="text" id="card-billing-address-admin-area-line-1" name="card-billing-address-admin-area-line-1" 
                              value={billingAddress.adminArea1}
                              onChange={(e) =>
                                handleBillingAddressChange("adminArea1", e.target.value)
                              }
                              required 
                            />
                        </div>

                        <div class="form-group">
                            <label for="cvv">City</label>
                            <input type="text" id="card-billing-address-admin-area-line-2" name="card-billing-address-admin-area-line-2" 
                              value={billingAddress.adminArea2}
                              onChange={(e) =>
                                handleBillingAddressChange("adminArea2", e.target.value)
                              }
                              required 
                            />
                        </div>
                      </div>

                      <div class="form-group-2">
                        <div class="form-group">
                            <label for="expiry-date">Country</label>
                            <input type="text" id="card-billing-address-country-code" name="card-billing-address-country-code"
                              value={billingAddress.countryCode}
                              onChange={(e) =>
                                handleBillingAddressChange("countryCode", e.target.value)
                              }
                              required 
                            />
                        </div>

                        <div class="form-group">
                            <label for="cvv">Zip Code</label>
                            <input type="text" id="card-billing-address-postal-code" name="card-billing-address-postal-code"
                              value={billingAddress.postalCode}
                              onChange={(e) =>
                                handleBillingAddressChange("postalCode", e.target.value)
                              }
                              required 
                            />
                        </div>
                      </div>

                
                      <SubmitPayment
                        isPaying={isPaying}
                        setIsPaying={setIsPaying}
                        billingAddress={billingAddress}
                        amount={order?.grandTotal}
                      />
                      
                    </PayPalCardFieldsProvider>
                  </PayPalScriptProvider>
                </>
                  
              :
                <h5 class="text-danger" style={{padding: '20px'}}>Unable to fetch order details please check cart Items</h5>
            }
       
          </div>
        </div>
      </div>
    </>
  );
}

const SubmitPayment = ({ isPaying, setIsPaying, billingAddress, amount }) => {
  const { cardFieldsForm, fields } = usePayPalCardFields();

  const handleClick = async () => {
    if (!cardFieldsForm) {
      const childErrorMessage =
        "Unable to find any child components in the <PayPalCardFieldsProvider />";

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    if (!formState.isFormValid) {
      return alert("The payment form is invalid");
    }
    setIsPaying(true);

    cardFieldsForm.submit({ billingAddress }).finally((err) => {
      setIsPaying(false);
    });
  };

  return (

   <>
    <button
      className={` ${isPaying ? 'btn-secindary' : 'pay-button'} `}
      onClick={handleClick}
    >
      {isPaying ? <div className="spinner tiny" /> : `Pay Now $${amount}`}
    </button>
    <p class="payment-note">Your payment is processed securely by PayPal.</p>
    </>
  );
};

const Message = ({ content }) => {
  return <p>{content}</p>;
};
