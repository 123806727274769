import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import './style.css';
import axios from "axios";

const Product = () => {
    const [cart, setCart] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
   // console.log(wishlist);

    
        const fetchWishlist = async () => {
            const custId = sessionStorage.getItem("VVKuserId");

            if (!custId) {
                setError("User not logged in.");
                navigate("/sign-in", { state: { from: location } });
                return;
            }

            try {
                const response = await axios.get(`https://api.vivekflowers.com/api/wishlist/${custId}`);
                setWishlist(response.data.wishlist || []);
            } catch (error) {
                setError("Failed to fetch wishlist. Please try again later.");
            }
        };

    useEffect(() => {
        fetchWishlist();
    }, [navigate]);

    const handleAddToCart = (product) => {
        setCart((prevCart) => {
            const existingProduct = prevCart.find((item) => item.id === product.id);
            if (existingProduct) {
                return prevCart.map((item) =>
                    item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
                );
            } else {
                return [...prevCart, { ...product, quantity: 1 }];
            }
        });
    };

    const handleAddToWishlist = (product) => {
        setWishlist((prevWishlist) => {
            if (prevWishlist.find((item) => item.id === product.id)) {
                return prevWishlist;
            }
            return [...prevWishlist, product];
        });
    };

    // Delete item from wishlist
    const handleRemoveFromWishlist = async (productId) => {
        const custId = sessionStorage.getItem("VVKuserId");

        if (!custId) {
            setError("User not logged in.");
            navigate("/sign-in", { state: { from: location } });
            return;
        }

        try {
            await axios.delete(`https://api.vivekflowers.com/api/wishlist/${productId}`);

            fetchWishlist();

        } catch (error) {
            setError("Failed to remove item from wishlist. Please try again later.");
        }
    };

    const cartCount = cart.reduce((acc, item) => acc + item.quantity, 0);
    const wishlistCount = wishlist.length;

    return (
        <>
            <Header cartCount={cartCount} wishlistCount={wishlistCount} />

            {/* Breadcrumb */}
            <div className="sticky-header-next-sec ec-breadcrumb section-space-mb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row ec_breadcrumb_inner">
                                <div className="col-md-6  col-sm-12">
                                    <h2 className="ec-breadcrumb-title">Wishlist</h2>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <ul className="ec-breadcrumb-list">
                                        <li className="ec-breadcrumb-item"><a href="/home">Home</a></li>
                                        <li className="ec-breadcrumb-item active">Wishlist</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb end */}

            {/* Wishlist */}
            <section className="shop-pro-content container">
                <div className="shop-pro-inner">
                    <div className="row">
                        <div className="ec-wish-rightside col-lg-12 col-md-12">
                            <div className="ec-compare-content">
                                <div className="ec-compare-inner">
                                    <div className="row margin-minus-b-30">
                                    {wishlist.length > 0 ?
                                        wishlist.map((product) => (
                                            <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6 mb-6 col-12 pro-gl-content" key={product.id}>
                                                <div className="ec-product-inner">
                                                    <div className="ec-pro-image-outer">
                                                        <div className="ec-pro-image">
                                                            <img
                                                                src={`https://api.vivekflowers.com/${product.image_url}`}
                                                                alt={product.product_name}
                                                                onError={(e) => e.target.src = 'fallback-image-url.jpg'} // fallback image on error
                                                            />
                                                            <span 
                                                                className="ec-com-remove ec-remove-wish" 
                                                                onClick={() => handleRemoveFromWishlist(product.wishlist_id)}>
                                                                ×
                                                            </span>
                                                            <div className="ec-pro-actions" style={{ marginTop: '20vh' }}>
                                                                {/* <a 
                                                                    className="add-to-cart" 
                                                                    title="Add to Cart" 
                                                                    onClick={() => handleAddToCart(product)}>
                                                                    <i className="fi-rr-shopping-basket"></i>
                                                                </a>
                                                                <a 
                                                                    className="ec-btn-group wishlist" 
                                                                    title="Wishlist" 
                                                                    onClick={() => handleAddToWishlist(product)}>
                                                                    <i className="fi-rr-heart"></i>
                                                                </a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ec-pro-content">
                                                    <h5 className="ec-pro-title"><a href={`/product?id=${product.product_id}`}>{product.product_name}</a></h5>
                                                        <div className="ec-pro-row" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <div className="ec-price">
                                                                <span className="new-price">${product.amount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : <p class="text-danger" align="center">No data in Whish List.</p>
                                }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Wishlist end */}

            <Footer />
        </>
    );
};

export default Product;
