import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Flower from "./flower";
import '../components/style.css';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";




const Home = () => {
    const [loader, setLoader] = useState(true);
    const [sliderImages, setSliderImages] = useState([]);
    const [postImages, setPostImages] = useState([]);
    // const [pooja, setPooja] = useState([]);
    // const [garlands, setGarlands] = useState([]);
    // const [festivals, setFestivals] = useState([]);
    // const [flowerString, setflowerString] = useState([]);
    // const [decorItems, setDecorItems] = useState([]);
    // const [occasions, setOccasions] = useState([]);
    const [panchang, setPanchang] = useState([]);
    const[banner,setBanner]=useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slide, setSlide] = useState(true);
    const intervalRef = useRef(null);

    //console.log(banner)



    // Fetch images from API
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get("https://api.vivekflowers.com/api/homepage");
                // const baseURL = "https://api.vivekflowers.com/uploads/";
                const baseURL = "https://api.vivekflowers.com/uploads/";

                // Separate slider and post images
                const sliderPaths = response.data.slider.map(item => `${baseURL}${item.icon}`);
                const postPaths = response.data.images.map(item => `${baseURL}${item}`);

                setSliderImages(sliderPaths);
                setPostImages(postPaths);
            //     setPooja(response.data.festivalSales);
            //     setFestivals(response.data.festivals);
            //     setflowerString(response.data.flowerString);
            //    // console.log(flowerString);
            //     setGarlands(response.data.wedding);
            //     setDecorItems(response.data.decorItems);
            //     setOccasions(response.data.occasions);
                setBanner(response.data.banners)
                setPanchang(response.data.panchang)
                setLoader(false);

            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchImages();
    }, []);

    // Automatic Slider
    useEffect(() => {
        if (sliderImages.length > 0) {
            intervalRef.current = setInterval(() => {
                setSlide(false);
                setTimeout(() => {
                    setSlide(true);
                    setCurrentSlide(prevSlide => (prevSlide + 1) % sliderImages.length);
                }, 1000);
            }, 7000);

            return () => clearInterval(intervalRef.current);
        }
    }, [sliderImages]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoader(false);
        }, 1500);
        return () => clearInterval(intervalId);
    }, []);

    const lastPanchang = panchang.length;
    const panchangData = panchang[lastPanchang-1];
    
    return (
        <>
            {loader && <Flower />}
            <Header />

            {panchangData && (
                <section class="section ec-category-section section-space-p">
                    <div class="container">
                        <div class="row margin-minus-b-15 margin-minus-t-15">
                                            
                            <Swiper
                                modules={[Autoplay, Pagination]}
                                spaceBetween={10}
                                slidesPerView={4}
                                // navigation
                                // pagination={{ clickable: true }}
                                autoplay={{ delay: 2000, disableOnInteraction: false }}
                                loop={true}
                                breakpoints={{
                                    0: { slidesPerView: 1 },
                                    400: { slidesPerView: 2 },
                                    600: { slidesPerView: 3 },
                                    700: { slidesPerView: 4 },
                                    1000: { slidesPerView: 5 },
                                    1200: { slidesPerView: 6 },
                                }}
                            >
                                <div id="ec-cat-slider" class="ec-cat-slider">
                                    <SwiperSlide>
                                        <div class="ec_cat_content ec_cat_content_1">
                                            <div class="ec_cat_inner ec_cat_inner-1">
                                                <div class="ec-category-image">
                                                    <img src="assets/images/icons/dress-8.png" class="svg_img" alt="drink" />
                                                </div>
                                                <div class="ec-category-desc">
                                                    <h3>Sunrise</h3>
                                                    <p class="cat-show-all">{panchangData.sunrise}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="ec_cat_content ec_cat_content_2">
                                            <div class="ec_cat_inner ec_cat_inner-2">
                                                <div class="ec-category-image">
                                                    <img src="assets/images/icons/coat-cat-8.png" class="svg_img" alt="drink" />
                                                </div>
                                                <div class="ec-category-desc">
                                                    <h3>Sunset</h3>
                                                    <p class="cat-show-all">{panchangData.sunset}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="ec_cat_content ec_cat_content_3">
                                            <div class="ec_cat_inner ec_cat_inner-3">
                                                <div class="ec-category-image">
                                                    <img src="assets/images/icons/glasses-8.png" class="svg_img" alt="drink" />
                                                </div>
                                                <div class="ec-category-desc">
                                                    <h3>Rahukal</h3>
                                                    <p class="cat-show-all">{panchangData.rahukal}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="ec_cat_content ec_cat_content_4">
                                            <div class="ec_cat_inner ec_cat_inner-4">
                                                <div class="ec-category-image">
                                                    <img src="assets/images/icons/shorts-cat-8.png" class="svg_img" alt="drink" />
                                                </div>
                                                <div class="ec-category-desc">
                                                    <h3>Yamagand</h3>
                                                    <p class="cat-show-all">{panchangData.yamaganda}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="ec_cat_content ec_cat_content_5">
                                            <div class="ec_cat_inner ec_cat_inner-5">
                                                <div class="ec-category-image">
                                                    <img src="assets/images/icons/POOJA.PNG" class="svg_img" alt="drink" />
                                                </div>
                                                <div class="ec-category-desc">
                                                    <h3>Tithi</h3>
                                                    <p class="cat-show-all">{panchangData.tidi}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div class="ec_cat_content ec_cat_content_6">
                                            <div class="ec_cat_inner ec_cat_inner-6">
                                                <div class="ec-category-image">
                                                    <img src="assets/images/icons/POOJA.PNG" class="svg_img" alt="drink" />
                                                </div>
                                                <div class="ec-category-desc">
                                                    <h3>Paksham</h3>
                                                    <p class="cat-show-all">{panchangData.paksham}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                            </Swiper>

                        </div>
                    </div>
                </section >
            )};

            {/* Main Slider */}
            <div className="ec-main-slider section section-space-pb">
                <div className="container" style={{ overflow: 'hidden' }}>
                    {sliderImages.map((imagePath, index) => (
                        <img
                            key={index}
                            className={`ec-slide-item ${slide ? 'active' : 'not-active'}`}
                            src={imagePath}
                            alt={`slide-${index}`}
                            style={{
                                width: '100%',
                                height: 'auto',
                                display: index === currentSlide ? "block" : "none",
                            }}
                        />
                    ))}
                </div>
            </div>



            


            {/* Posts Section */}
            <section className="section ec-instagram-section">
                <div className="container image-center">
                    {postImages.length > 0 ? (
                        postImages.map((image, index) => (
                            <img src={image} width="700px" height="auto" alt="Diwali" />

                        ))
                    ) : (
                        <p>Loading posts...</p>
                    )}
                </div>
            </section>









            {banner && banner.length > 0 && banner.map((banner) => (
                <section className="section ec-category-section ec-category-wrapper-4 section-space-p" key={banner.id}>
                    <div className="container">
                    <img
                   className="img-fluid"
                   src={`https://api.vivekflowers.com/uploads/${banner.image}`}
                   width="100%"
                   height="100%"
                   style={{ marginBottom: '30px' }}
                   alt={banner.name}
                 />
                 <div className="row cat-space-3">
                   {banner.subcategory && banner.subcategory.length > 0 && banner.subcategory.map((subcat) => (
                     <div className="col-lg-2 col-md-4 col-6" key={subcat.id}>
                       <div className="cat-card" style={{ height: '230px' }}>
                         <div className="card-img">
                           <a href={`/category?id=${subcat.name}`}>
                             <img 
                               className="cat-icon" 
                               src={`https://api.vivekflowers.com/${subcat.image_url}`} 
                               alt={subcat.name} 
                             />
                           </a>
                           <h6 className="text-center category-items">{subcat.name}</h6>
                         </div>
                       </div>
                     </div>
                   ))}
                 </div>
               </div>
             </section>
           ))}


            {/* festival Sales */}
            {/* {pooja&&pooja.length > 0 && (
    <section className="section ec-category-section ec-category-wrapper-4 section-space-p">
        <div className="container">
            <img
                className="img-fluid"
                src="assets/img/festivals/fest-sales.jpg"
                width="100%"
                height="100%"
                style={{ marginBottom: '30px' }}
                alt="Category Image"
            />
            <div className="row cat-space-3">
                {pooja.map((poojas, index) => (
                    <div className="col-lg-2 col-md-4 col-6" key={index}>
                        <div className="cat-card" style={{ height: '230px' }}>
                            <div className="card-img">
                                <a href={`/category?id=${poojas.name}`}>
                                    <img className="cat-icon" src={`https://api.vivekflowers.com/${poojas.image_url}`} alt={poojas.name} />
                                </a>
                                <h6 className="text-center category-items">{poojas.name}</h6>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
)} */}

            {/* festival Sales end */}


            {/* Flower String */}
            {/* <section class="section ec-category-section ec-category-wrapper-4 section-space-p">

                <div class="container">

                    <img class="img-fluid" src="../assets/img/flowerstring/title1.jpg" width="100%" height="100%" style={{ marginBottom: '30px' }} alt="Category Image" />


                    <div class="row cat-space-3">


                        {flowerString&&flowerString.map((flower, index) => (

                            <div class="col-lg-2 col-md-4 col-6" key={index}>

                                <div class="cat-card" style={{ height: '230px' }}>

                                    <div class="card-img">

                                        <a href={`/product?id=${flower.p_id}`}><img class="cat-icon" src={`https://api.vivekflowers.com/${flower.image_url}`} alt={flower.productName} /></a>

                                        <h6 class="text-center category-items">{flower.productName}</h6>

                                    </div>

                                </div>

                            </div>

                        ))}
                    </div>

                </div>

            </section> */}
            {/* Flower String end */}


            {/* Wedding garlands */}
            {/* <section class="section ec-category-section ec-category-wrapper-4 section-space-p">

                <div class="container">

                    <img class="img-fluid" src="./assets/img/wedding/wedding-title2.jpg" width="100%" height="100%" style={{ marginBottom: '30px' }} alt="Category Image" />


                    <div class="row cat-space-3">


                        {garlands&&garlands.slice(0, 5).map((garland, index) => (

                            <div class="col-lg-2 col-md-4 col-6" key={index}>

                                <div class="cat-card" style={{ height: '230px' }}>

                                    <div class="card-img">

                                        <a href={`/category?id=${garland.name}`}><img class="cat-icon" src={`https://api.vivekflowers.com/${garland.image_url}`} alt={garland.name} /></a>

                                        <h6 class="text-center category-items">{garland.name}</h6>

                                    </div>

                                </div>

                            </div>

                        ))}
                    </div>

                </div>

            </section> */}
            {/* Wedding garlands end */}


            {/* Decor Items */}

            {/* <section class="section ec-category-section ec-category-wrapper-4 section-space-p">

                <div class="container">

                    <img class="img-fluid" src="./assets/img/decor/decor-title.jpg" width="100%" height="100%" style={{ marginBottom: '30px' }} alt="Category Image" />


                    <div class="row cat-space-3">


                        {decorItems&&decorItems.map((decor, index) => (

                            <div class="col-lg-2 col-md-4 col-6" key={index}>

                                <div class="cat-card" style={{ height: '230px' }}>

                                    <div class="card-img">

                                        <a href={`/category?id=${decor.name}`}><img class="cat-icon" src={`https://api.vivekflowers.com/${decor.image_url}`} alt={decor.name} /></a>

                                        <h6 class="text-center category-items">{decor.name}</h6>

                                    </div>

                                </div>

                            </div>

                        ))}
                    </div>

                </div>

            </section> */}
            {/* Decor Items end */}



            {/* festival  */}
            {/* <section class="section ec-category-section ec-category-wrapper-4 section-space-p">

                <div class="container">

                    <img class="img-fluid" src="../assets/img/Festival/title5.jpg" width="100%" height="100%" style={{ marginBottom: '30px' }} alt="Category Image" />


                    <div class="row cat-space-3">


                        {festivals&&festivals.map((festival, index) => (

                            <div class="col-lg-2 col-md-4 col-6" key={index}>

                                <div class="cat-card" style={{ height: '230px' }}>

                                    <div class="card-img">

                                        <a href={`/category?id=${festival.name}`}><img class="cat-icon" src={`https://api.vivekflowers.com/${festival.image_url}`} alt={festival.name} /></a>

                                        <h6 class="text-center category-items">{festival.name}</h6>

                                    </div>

                                </div>

                            </div>

                        ))}
                    </div>

                </div>

            </section> */}

            {/* festival  end */}

            {/* occasions */}
            {/* <section class="section ec-category-section ec-category-wrapper-4 section-space-p">

                <div class="container">

                    <img class="img-fluid" src="../assets/img/occasions/title6.jpg" width="100%" height="100%" style={{ marginBottom: '30px' }} alt="Category Image" />


                    <div class="row cat-space-3">


                        {occasions&&occasions.map((occasion, index) => (

                            <div class="col-lg-2 col-md-4 col-6" key={index}>

                                <div class="cat-card" style={{ height: '230px' }}>

                                    <div class="card-img">

                                        <a href={`/category?id=${occasion.name}`}><img class="cat-icon" src={`https://api.vivekflowers.com/${occasion.image_url}`} alt={occasion.name} /></a>

                                        <h6 class="text-center category-items">{occasion.name}</h6>

                                    </div>

                                </div>

                            </div>

                        ))}
                    </div>

                </div>

            </section> */}

            {/* occasions end */}



            <Footer />


        </>
    );
};

export default Home;
