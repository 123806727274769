import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Flower from './flower';


const CheckOut = () => {
    
    const navigate = useNavigate();
    const userId = sessionStorage.getItem('VVKuserId') || 0;

    const [userZip, setUserZip] = useState('');

    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [qty, setQty] = useState(0);
    const [shipCharge, setShipCharge] = useState(0);
    const [shipMethod, setShipMethod] = useState('');

    const [poojaItems, setPoojaItems] = useState([]);
    const [poojaTotal, setPoojaTotal] = useState(0);
    const [poojaShipCharge, setPoojaShipCharge] = useState(0);
    const [shipMethodPooja, setShipMethodPooja] = useState('');

    const [grandTotal, setGrandTotal] = useState(0);
    //const [paymentUrl, setPaymentUrl] = useState(false);
    //const [gst, setGST] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [zipStatus, setZipStatus] = useState(false);

    const [paymentButton, setPayment] = useState(true);

    const [fPost, setFpost] = useState('75038');
    const [weight, setWeight] = useState(0);

    const [shipping, setShipping] = useState([]);
    const [option, setOption] = useState('pickup');
    const [formData, setFormData] = useState({});
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
    const [selectedPoojaDeliveryDate, setSelectedPoojaDeliveryDate] = useState('');
    const [selectedEventDate, setSelectedEventDate] = useState('');

    const [unavailableDeliveryDates, setUnavailableDeliveryDates] = useState([]);
    const [unavailableEventDates, setUnavailableEventDates] = useState([]);

    const [cartDetails, setCartDetails] = useState(true);
    const [poojaDetails, setPoojaDetails] = useState(true);

    const [poojaSaturdayCharge, setPoojaSaturdayCharge] = useState(0);
    const [cartSaturdayCharge, setCartSaturdayCharge] = useState(0);
    const [cartSaturday, setCartSaturday] = useState(false);
    const [poojaSaturday, setPoojaSaturday] = useState(false);


    const toggleCartVisibility = () => {
        setCartDetails(!cartDetails);
    };

    const togglePoojaVisibility = () => {
        setPoojaDetails(!poojaDetails);
    };

   

    const getCart = () => {
        const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(cartItems);

        const poojaItems = JSON.parse(sessionStorage.getItem('poojaItems')) || [];
        setPoojaItems(poojaItems);
            
        let total = 0;
        let cartWeight = 0;
        let qty = 0;

        cartItems.forEach(item => {
            total += parseFloat(item.price) * parseFloat(item.qty)
            if(item.iceWeight && item.iceWeight !== null && parseFloat(item.iceWeight) > 0 ){
                cartWeight += parseFloat(item.iceWeight) * parseFloat(item.qty)
            }else{
                cartWeight += parseFloat(item.weight) * parseFloat(item.qty)
            }
            qty += parseFloat(item.qty)
        });


        let poojaTotal = 0;
        let poojaWeight = 0;
        let poojaQty = 0;

        poojaItems.forEach(item => {
            poojaTotal += parseFloat(item.price) * parseFloat(item.qty)
            if(item.iceWeight && item.iceWeight !== null && parseFloat(item.iceWeight) > 0){
                poojaWeight += parseFloat(item.iceWeight) * parseFloat(item.qty)
            }else{
                poojaWeight += parseFloat(item.weight) * parseFloat(item.qty)
            }
            poojaQty += parseFloat(item.qty)
        });

        const weightG = parseFloat(cartWeight) + parseFloat(poojaWeight);
        //const weightLb = parseFloat(weightG/453.5921).toFixed(2);

        setWeight(weightG);

        setTotal(total);
        setQty( parseFloat(qty) + parseFloat(poojaQty) );
        const gst = (parseFloat(total)*18)/100;
        //setGST(gst);

        setPoojaTotal(poojaTotal);
        const poojaGst = (parseFloat(poojaTotal)*18)/100;
        //setPoojaGST(poojaGst);
    };

    useEffect(() => { 
        if(option === 'pickup'){
            setShipCharge(0);
            setShipMethod('');
            setPoojaShipCharge(0);
            setShipMethodPooja('');
            setGrandTotal(total + poojaTotal);
        }else{

            setFormData((prevData) => ({
                ...prevData,
                ['zip']: userZip,
                ['city']: '',
                ['state']: '',
                ['country']: ''
            }));
        }
    },[total, option]);
        
    useEffect(() => {
        const intervalId = setInterval(() => {
            getCart();
            setLoader(false);
        }, 500);
        return () => clearInterval(intervalId);
    }, []);

   

    const toggleCoupon = () => {
        setIsOpen(!isOpen);
    };

    const getZip = async (zip) => {

        try{
            const response = await axios.post(`https://api.vivekflowers.com/api/zipcode/${zip}`);
            
            //console.log(response.data);

            if(response.data.status && response.data.status === 'error'){
                setZipStatus(false);
            }else{
                
                if(response.data[0]){
                    setFormData((prevData) => ({
                        ...prevData,
                        ['city']: response.data[0].city,
                        ['state']: response.data[0].stateName,
                        ['country']: response.data[0].CountryName
                    }));
                    getShipping(zip, cartSaturdayCharge, poojaSaturdayCharge, cartSaturday, poojaSaturday);

                    if(parseInt(response.data[0].zip) === parseInt(zip)){
                        setZipStatus(true);
                    }else{
                        setZipStatus(false);
                    }
                    
                }

                
            }

        }catch(error){
            console.error(error);
        }

    };

    const getShipping = async (zip,  cCharge, pCharge, cSaturday, pSaturday) => {

        try{
            const response = await axios.post(`https://api.vivekflowers.com/get-rate/${fPost}/${zip}/${weight}`);

            //console.log(response.data);

            setShipping(response.data);

            const defaultShipp = response?.data?.rates?.filter(rates => rates.serviceType ===  'PRIORITY_OVERNIGHT');
            const defaultRate = parseFloat(parseFloat(defaultShipp[0].totalNetFedExCharge.replace('$', ''))).toFixed(2);
            const defaultMethod = defaultShipp[0].serviceType;

            const defaultShippPooja = response?.data?.rates?.filter(rates => rates.serviceType ===  'FEDEX_GROUND');
            const defaultRatePooja = parseFloat(parseFloat(defaultShippPooja[0].totalNetFedExCharge.replace('$', ''))).toFixed(2);
            const defaultMethodPooja = defaultShippPooja[0].serviceType;

            let cartAmount = 0;
            let cartShip = 0
            let poojaAmount = 0;
            let poojaShip = 0

            if(cartItems.length > 0){
                cartAmount = total;
                cartShip = parseFloat(defaultRate) + (parseFloat(defaultRate) *10/100) + (cSaturday ? parseFloat(cCharge) : 0);
                setShipCharge(cartShip);
                setShipMethod(defaultMethod);
            }

            if(poojaItems.length > 0){
                poojaAmount = poojaTotal;
                poojaShip = parseFloat(defaultRatePooja) + (parseFloat(defaultRatePooja) *10/100) + (pSaturday ? parseFloat(pCharge) : 0);
                setPoojaShipCharge(poojaShip);
                setShipMethodPooja(defaultMethodPooja);
            }

            setGrandTotal( ( parseFloat(cartAmount) + parseFloat(cartShip) + parseFloat(poojaAmount) + parseFloat(poojaShip) ).toFixed(2) );

            setPayment(true);

        }catch(error){
            console.error(error);
        }
    };

    const getShippingHolidays = async () => {
        try{
            const response = await axios.get(`https://api.vivekflowers.com/api/shippingholidays`);

            //console.log(response.data);

            setUnavailableDeliveryDates(response.data);

        }catch(error){
            console.error(error);
        }
    };

     useEffect(() => { getShippingHolidays(); },[]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
        if (name === 'zip' && value.length > 4) {
            getZip(value);
        }
        if (name === 'zip' && value.length <= 4) {
            setFormData((prevData) => ({
                ...prevData,
                ['city']: '',
                ['state']: '',
                ['country']: ''
            }));
            setShipCharge(0);
            setShipMethod('');
            setPoojaShipCharge(0);
            setShipMethodPooja('');
            setGrandTotal(total + poojaTotal);
            setZipStatus(false);
        }
    };

    const handleDeliveryOption = (option) => {
        setOption(option);

        if(option === 'pickup'){
            setShipCharge(0);
            setShipMethod('');
            setPoojaShipCharge(0);
            setShipMethodPooja('');
            setGrandTotal(total + poojaTotal);
        }else{

            setFormData((prevData) => ({
                ...prevData,
                ['zip']: userZip,
                ['city']: '',
                ['state']: '',
                ['country']: ''
            }));

            getZip(userZip);
        }
    };

    // const handleDelivery = (method, charges) => {
    //     setShipMethod(method);
    //     setShipCharge(charges);
    //     setGrandTotal( (parseFloat(total) + parseFloat(charges)).toFixed(2) );
    // };


    const isUnavailableDeliveryDate = (date) => {
        const formattedDate = date.toISOString().split("T")[0];
        return unavailableDeliveryDates.includes(formattedDate);
    };
    
    

    const isUnavailableEventDate = (date) => {
        const formattedDate = date.toISOString().split("T")[0];
        return unavailableEventDates.includes(formattedDate);
    
    };
    
    useEffect(() => {

        let date = new Date(selectedDeliveryDate);
        let day = String(date.getDate()).padStart(2, '0');
        let month = date.toLocaleString('default', { month: 'short' });
        let year = date.getFullYear();
        let formattedDate = `${month} ${day}, ${year}`;

        setFormData((prevData) => ({
            ...prevData,
            ['dDate']: formattedDate,
        }));

    },[selectedDeliveryDate]);

    useEffect(() => {

        let date = new Date(selectedEventDate);
        let day = String(date.getDate()).padStart(2, '0');
        let month = date.toLocaleString('default', { month: 'short' });
        let year = date.getFullYear();
        let formattedDate = `${month} ${day}, ${year}`;

        setFormData((prevData) => ({
            ...prevData,
            ['eDate']: formattedDate,
        }));

    },[selectedEventDate]);

    useEffect(() => {

        let date = new Date(selectedPoojaDeliveryDate);
        let day = String(date.getDate()).padStart(2, '0');
        let month = date.toLocaleString('default', { month: 'short' });
        let year = date.getFullYear();
        let formattedDate = `${month} ${day}, ${year}`;

        setFormData((prevData) => ({
            ...prevData,
            ['poojaDDate']: formattedDate,
        }));

    },[selectedPoojaDeliveryDate]);


    const placeOrder = (e) => {
        e.preventDefault();
        const sendData = {
            cust_id: userId,
            deliveryMethod: option,
            customerData: formData,
            cartData: cartItems,
            poojaData: poojaItems,
            cartTotal: parseFloat(total),
            poojaTotal: parseFloat(poojaTotal),
            grandTotal: parseFloat(grandTotal),
            shipCharges: parseFloat(shipCharge),
            poojaShipCharge: parseFloat(poojaShipCharge),
            shipMethod: shipMethod,
            poojaShipMethod: shipMethodPooja
        };
        navigate('/payment', { state: {sendData} });
    };

    const placeOrderOld = async (e) => {

        setPayment(false);

        e.preventDefault();

        const sendData = {
            cust_id: userId,
            deliveryMethod: option,
            customerData: formData,
            cartData: cartItems,
            poojaData: poojaItems,
            cartTotal: parseFloat(total),
            poojaTotal: parseFloat(poojaTotal),
            grandTotal: parseFloat(grandTotal),
            shipCharges: parseFloat(shipCharge),
            poojaShipCharge: parseFloat(poojaShipCharge),
            shipMethod: shipMethod,
            poojaShipMethod: shipMethodPooja
        };

        //console.log(sendData);

        try{

            const response = await axios.post(`https://api.vivekflowers.com/api/order`, sendData);

           // console.log(response.data);

            if(response.data.paymentId){
                const paymentData = {
                    url: `https://www.paypal.com/checkoutnow?token=${response.data.paymentId}`,
                    id: response.data.paymentId
                }

                sessionStorage.setItem('paymentData', JSON.stringify(paymentData));

                window.location = `https://www.paypal.com/checkoutnow?token=${response.data.paymentId}`;
            }else{
                alert('Unable to process your request!');
                setPayment(true);
            }

        }catch(error){
            console.error(error);
        }

        //setPayment(true);
    }; 



    const getUser = async (user) => {
        try{
            const response = await axios.get(`https://api.vivekflowers.com/api/customer/${user}`);
            
            //console.log(response.data);

            if(response.data.cust_id && response.data.cust_id === parseInt(userId)){

                setFormData((prevData) => ({
                    ...prevData,
                    ['fName']: response.data.first_name,
                    ['lName']: response.data.last_name,
                    ['address1']: response.data.address,
                    ['address2']: response.data.address_line2,
                    ['address2']: response.data.address_line2,
                    ['address2']: response.data.address_line2,
                    ['email']: response.data.email,
                    ['phone']: response.data.phone
                }));

                if(response.data.postalcode && response.data.postalcode.length > 4){
                    setUserZip(response.data.postalcode);
                }

            }

        }catch(error){
            console.error(error);
        }
    };

    useEffect(() => {
        if(userId && parseInt(userId) > 0){
            getUser(userId);
        }
    },[userId]);

    useEffect(() => {
        document.cookie = "myCookie=value; SameSite=None; Secure; path=/";
    }, []);


    return (

        <>{loader && <Flower /> }

            <div className="checkout_page">

                <Header />

                {/* breadcrumb */}
                <div class="sticky-header-next-sec  ec-breadcrumb section-space-mb">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="row ec_breadcrumb_inner">
                                    <div class="col-md-6 col-sm-12">
                                        <h2 class="ec-breadcrumb-title">Checkout</h2>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <ul class="ec-breadcrumb-list">
                                            <li class="ec-breadcrumb-item"><a href="/">Home</a></li>
                                            <li class="ec-breadcrumb-item active">Checkout</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* breadcrumb end */}

                {/* register */}

                <section class="ec-page-content section-space-p">
                    <div class="container">
                       
                {cartItems.length > 0 || poojaItems.length > 0?

                    <form onSubmit={placeOrderOld}>

                        <div class="row">
                        
                            <div class="ec-checkout-leftside col-lg-8 col-md-12 ">
                                <div class="row">

                                    <div class="col-lg-3 col-6 mt-2">
                                        <p>New Customer?</p>
                                        <a href="/sign-up" class="btn btn-primary w-100">Sign Up</a>
                                    </div>

                                    <div class="col-lg-3 col-6 mt-2">
                                        <p>Already Sign Up?</p>
                                        <a href="/sign-in" class="btn btn-success w-100">Sign In</a>
                                    </div>

                                    <div class="col-lg-6 col-12 mt-2">
                                        <div class="ec-bl-block-content">
                                            <div class="ec-check-subtitle text-center">Checkout Options</div>
                                            <div class="row mt-2">
                                                <div class="col-lg-6 col-6 mt-3">
                                                    <input type="radio" id="storePickup" name="option" value="pickup"  checked={option === "pickup"} onChange={() => handleDeliveryOption("pickup")} />
                                                    <label htmlFor="storePickup" style={{fontWeight: option === "pickup" && '600', color: option === "pickup" && 'green', borderBottom: option === "pickup" && '2px solid green', paddingBottom: option === "pickup" && '10px'}}>Store Pickup</label>
                                                </div>    
                                                <div class="col-lg-6 col-6 mt-3">
                                                    <input type="radio" id="homeDelivery" name="option" value="delivery" checked={option === "delivery"} onChange={() => handleDeliveryOption("delivery")} />
                                                    <label htmlFor="homeDelivery" style={{fontWeight: option === "delivery" && '600', color: option === "delivery" && 'green', borderBottom: option === "delivery" && '2px solid green', paddingBottom: option === "delivery" && '10px'}}>Delivery to Home</label>
                                                </div>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    {option === 'pickup' ? 

                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <div class="row">

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">Select Store</label>
                                                <select name="pickupLocation" class="form-select" value={formData.pickupLocation} onChange={handleInputChange} required>
                                                    <option value="" hidden>Select Pickup Location</option>
                                                    <option value="Edison">Edison</option>
                                                    <option value="Eastwindsor" >Eastwindsor</option>
                                                    <option value="Piscataway" >Piscataway</option>
                                                    <option value="Jersey City" >Jersey City</option>
                                                    <option value="South Brunswick" >South Brunswick</option>
                                                    <option value="Frisco" >Frisco</option>
                                                    <option value="Plano" >Plano</option>
                                                    <option value="Irving" >Irving</option>
                                                    <option value="Eldorado" >Eldorado</option>
                                                    <option value="Alpharetta" >Alpharetta</option>
                                                    <option value="Suwanee" >Suwanee</option>
                                                    <option value="Schaumburg" >Schaumburg</option>
                                                    <option value="Napeville" >Napeville</option>
                                                    <option value="Chantilly" >Chantilly</option>
                                                    <option value="Ashburn" >Ashburn</option>
                                                    <option value="Arizona" >Arizona</option>
                                                    <option value="Boston" >Boston</option>
                                                    <option value="Guruvaayoorappan Temple" >Guruvaayoorappan Temple</option>
                                                    <option value="Plano Coit Road" >Plano Coit Road</option>
                                                    <option value="North Carolina City" >North Carolina City</option>
                                                </select>
                                                {(!formData.pickupLocation || formData.pickupLocation.length === 0) && <p className="text-danger">Please select a store for Pickup</p>}

                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">First Name</label>
                                                <input type="text" className="form-control" name="fName" value={formData.fName} onChange={handleInputChange} required />
                                                {(!formData.fName || formData.fName.length === 0) && <p className="text-danger">Please Enter First Name</p>}
                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">Last Name</label>
                                                <input type="text" className="form-control" name="lName" value={formData.lName} onChange={handleInputChange} required />
                                                {(!formData.lName || formData.lName.length === 0) && <p className="text-danger">Please Enter Last Name</p>}
                                            </div>

                                            <div class="col-lg-6 col-12 mt-2">
                                                <label htmlFor="storePickup">Phone</label>
                                                <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleInputChange} required />
                                                {(!formData.phone || formData.phone.length === 0) && <p className="text-danger">Please Enter Contact Number</p>}
                                            </div>

                                            <div class="col-lg-6 col-12 mt-2">
                                                <label htmlFor="storePickup">Email</label>
                                                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} required />
                                                {(!formData.email || formData.email.length === 0) && <p className="text-danger">Please Enter Email ID</p>}
                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">Event Type</label>
                                                <select name="event" class="form-select" value={formData.event} onChange={handleInputChange} required>
                                                    <option value="" hidden>Select Event Type</option>
                                                    <option value="General">General</option>
                                                    <option value="Wedding" >Wedding</option>
                                                    <option value="Engagement" >Engagement</option>
                                                </select>
                                                {(!formData.event || formData.event.length === 0) && <p className="text-danger">Please Select an Event Type</p>}
                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">Event Date</label>
                                                <DatePicker selected={selectedEventDate} onChange={(date) => {

                                                  if(date && date !== null){
                                                    if (isUnavailableEventDate(date)) {
                                                        alert("This date is Unavailable. Please Choose another Date.");
                                                    } else {
                                                        setSelectedEventDate(date);
                                                    }
                                                  }
                                                }}
                                                filterDate={(date) => {
                                                    const isPastDate = date < new Date();  // Check if the date is in the past
                                                    return !isUnavailableEventDate(date) && !isPastDate;
                                                }}
                                                dateFormat="dd-MM-yyyy" className="form-control" />
                                                {(!selectedEventDate || selectedEventDate.length === 0) && <p className="text-danger">Please Select an Event Date</p>}
                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">Pickup Date</label>
                                                <DatePicker 
                                                    selected={selectedDeliveryDate} 
                                                    onChange={(date) => {
                                                        if (date && date !== null) {
                                                            const isSaturday = date.getDay() === 6;
            
                                                            // If it's Saturday, ask for user confirmation
                                                            if (isSaturday) {
                                                                const userConfirmed = window.confirm("This date is Saturday.");
                                                                if (userConfirmed) {
                                                                    setSelectedDeliveryDate(date);
                                                                }
                                                            } else if (isUnavailableDeliveryDate(date)) {
                                                                alert("This date is Unavailable. Please Choose another Date.");
                                                            } else {
                                                                setSelectedDeliveryDate(date);
                                                            }
                                                        }
                                                    }}
                                                    filterDate={(date) => {
                                                        // Disable past dates and Sundays
                                                        const isSunday = date.getDay() === 0;
                                                        const isMonday = date.getDay() === 1;
                                                        const isPastDate = date < new Date();  // Check if the date is in the past
                                                        return !isUnavailableDeliveryDate(date) && !isSunday &&!isMonday && !isPastDate;
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="form-control"
                                                />
                                                {(!selectedDeliveryDate || selectedDeliveryDate.length === 0) && <p className="text-danger">Please Select a Pickup Date</p>}  
                                            </div>

                                        </div>
                                    </div>

                                    :

                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <div class="row">
                                        <div class="col-lg-6 col-12 mt-2">
                                                <label htmlFor="storePickup">First Name</label>
                                                <input type="text" className="form-control" name="fName" value={formData.fName} onChange={handleInputChange} required />
                                                {(!formData.fName || formData.fName.length === 0) && <p className="text-danger">Please Enter First Name</p>}
                                            </div>

                                            <div class="col-lg-6 col-12 mt-2">
                                                <label htmlFor="storePickup">Last Name</label>
                                                <input type="text" className="form-control" name="lName" value={formData.lName} onChange={handleInputChange} required />
                                                {(!formData.lName || formData.lName.length === 0) && <p className="text-danger">Please Enter Last Name</p>}
                                            </div>

                                            <div class="col-lg-6 col-12 mt-2">
                                                <label htmlFor="storePickup">Phone</label>
                                                <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleInputChange} required />
                                                {(!formData.phone || formData.phone.length === 0) && <p className="text-danger">Please Enter Contact Number</p>}
                                            </div>

                                            <div class="col-lg-6 col-12 mt-2">
                                                <label htmlFor="storePickup">Email</label>
                                                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} required />
                                                {(!formData.email || formData.email.length === 0) && <p className="text-danger">Please Enter Email ID</p>}
                                            </div>

                                            <div class="col-lg-12 col-12 mt-2">
                                                <label htmlFor="storePickup">Address Line 1</label>
                                                <input type="text" className="form-control" name="address1" value={formData.address1} onChange={handleInputChange} required />
                                                {(!formData.address1 || formData.address1.length === 0) && <p className="text-danger">Please Enter Address Line 1</p>}
                                            </div>

                                            <div class="col-lg-12 col-12 mt-2">
                                                <label htmlFor="storePickup">Address Line 2</label>
                                                <input type="text" className="form-control" name="address2" value={formData.address2} onChange={handleInputChange}  />
                                                {/* {(!formData.address2 || formData.address2.length === 0) && <p className="text-danger">Please Enter Address Line 2</p>} */}
                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">Zip Code</label>
                                                <input type="text" className="form-control" name="zip" value={formData.zip} onChange={handleInputChange} required />
                                                {(!formData.zip || formData.zip === 0) && <p className="text-danger">Please Enter Zip Code</p>}
                                                {formData.zip && formData.zip !== '' && !zipStatus && 
                                                    <p class="text-danger">Invalid Zip Code</p> 
                                                }

                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">City</label>
                                                <input type="text" readOnly className="form-control" name="city" value={formData.city} onChange={handleInputChange} required />
                                            </div>

                                            <div class="col-lg-4 col-12 mt-2">
                                                <label htmlFor="storePickup">State</label>
                                                <input type="text" readOnly className="form-control" name="state" value={formData.state} onChange={handleInputChange} required />
                                            </div>

                                            {/* <div class="col-lg-3 col-12 mt-2">
                                                <label htmlFor="storePickup">County/Region</label>
                                                <input type="text" readOnly className="form-control" name="country" value={formData.country} onChange={handleInputChange} required />
                                            </div> */}

                                            <div class={`${poojaItems.length > 0 ? 'col-lg-3' : 'col-lg-4'} col-12 mt-2`}>
                                                <label htmlFor="storePickup">Event Type</label>
                                                <select name="event" class="form-select" value={formData.event} onChange={handleInputChange} required>
                                                    <option value="" hidden>Select Event Type</option>
                                                    <option value="General">General</option>
                                                    <option value="Wedding" >Wedding</option>
                                                    <option value="Engagement" >Engagement</option>
                                                    <option value="Anniversary" >Anniversary</option>
                                                </select>
                                                {(!formData.event || formData.event.length === 0) && <p className="text-danger">Please Select an Event Type</p>}
                                            </div>

                                            <div class={`${poojaItems.length > 0 ? 'col-lg-3' : 'col-lg-4'} col-12 mt-2`}>
                                                <label htmlFor="storePickup">Event Date</label>
                                                <DatePicker selected={selectedEventDate} onChange={(date) => {
                                                  if(date && date !== null){
                                                    if (isUnavailableEventDate(date)) {
                                                        alert("This date is Unavailable. Please Choose another Date.");
                                                    } else {
                                                        setSelectedEventDate(date);
                                                    }
                                                  }
                                                }}
                                                filterDate={(date) => {
                                                    const isPastDate = date < new Date();  // Check if the date is in the past
                                                    return !isUnavailableEventDate(date) && !isPastDate;
                                                }}
                                                dateFormat="dd-MM-yyyy" className="form-control" />
                                                {(!selectedEventDate || selectedEventDate.length === 0) && <p className="text-danger">Please Select an Event Date</p>}
                                            </div>

                                            <div class={`${poojaItems.length > 0 ? 'col-lg-3' : 'col-lg-4'} col-12 mt-2`}>
                                                <label htmlFor="storePickup">Delivery Date</label>
                                                <DatePicker 
                                                    selected={selectedDeliveryDate} 
                                                    onChange={(date) => {
                                                        if (date && date !== null) {
                                                            const isSaturday = date.getDay() === 6;
            
                                                            // If it's Saturday, ask for user confirmation
                                                            if (isSaturday) {
                                                                const userConfirmed = window.confirm("No delivery on Sunday. If you want delivery on Saturday, there is an extra cost $20 than the weekdays, as charged by FedEx.");
                                                                if (userConfirmed) {
                                                                    setPayment(false);
                                                                    setSelectedDeliveryDate(date);
                                                                    setCartSaturdayCharge(20);
                                                                    setCartSaturday(true);
                                                                    if(formData.zip && formData.zip.length > 4){
                                                                        getShipping(formData.zip, 20, poojaSaturdayCharge, true, poojaSaturday);
                                                                    } 
                                                                }
                                                            } else if (isUnavailableDeliveryDate(date)) {
                                                                alert("This date is Unavailable. Please Choose another Date.");
                                                            } else {
                                                                setPayment(false);
                                                                setSelectedDeliveryDate(date);
                                                                setCartSaturdayCharge(0);
                                                                setCartSaturday(false);
                                                                if(formData.zip && formData.zip.length > 4){
                                                                    getShipping(formData.zip, 0, poojaSaturdayCharge, false, poojaSaturday);
                                                                } 
                                                            }
                                                        }
                                                    }}
                                                    filterDate={(date) => {
                                                        // Disable past dates and Sundays
                                                        const isSunday = date.getDay() === 0;
                                                        const isMonday = date.getDay() === 1;
                                                        const isPastDate = date < new Date();  // Check if the date is in the past
                                                        return !isUnavailableDeliveryDate(date) && !isSunday &&!isMonday && !isPastDate;
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="form-control"
                                                />
                                                {(!selectedDeliveryDate || selectedDeliveryDate.length === 0) && <p className="text-danger">Please Select a Delivery Date</p>}
                                            </div>
                                            {poojaItems.length > 0 &&
                                            <div class="col-lg-3 col-12 mt-2">
                                                <label htmlFor="storePickup">Delivery Date for Pooja Items</label>
                                                <DatePicker 
                                                    selected={selectedPoojaDeliveryDate} 
                                                    onChange={(date) => {
                                                        if (date && date !== null) {
                                                            const isSaturday = date.getDay() === 6;
            
                                                            // If it's Saturday, ask for user confirmation
                                                            if (isSaturday) {
                                                                const userConfirmed = window.confirm("No delivery on Sunday. If you want delivery on Saturday, there is an extra cost $20 than the weekdays, as charged by FedEx.");
                                                                if (userConfirmed) {
                                                                    setPayment(false);
                                                                    setSelectedPoojaDeliveryDate(date);
                                                                    setPoojaSaturdayCharge(20);
                                                                    setPoojaSaturday(true);
                                                                    if(formData.zip && formData.zip.length > 4){
                                                                        getShipping(formData.zip, cartSaturdayCharge, 20, cartSaturday, true);
                                                                    } 
                                                                }
                                                            } else if (isUnavailableDeliveryDate(date)) {
                                                                alert("This date is Unavailable. Please Choose another Date.");
                                                            } else {
                                                                setPayment(false);
                                                                setSelectedPoojaDeliveryDate(date);
                                                                setPoojaSaturdayCharge(0);
                                                                setPoojaSaturday(false);
                                                                if(formData.zip && formData.zip.length > 4){
                                                                    getShipping(formData.zip, cartSaturdayCharge, 0, cartSaturday, false);
                                                                } 
                                                            }
                                                        }
                                                    }}
                                                    filterDate={(date) => {
                                                        const today = new Date();
                                                        const next7Days = new Date(today);
                                                        next7Days.setDate(today.getDate() + 7);
                                                    
                                                        const isSunday = date.getDay() === 0;
                                                        const isPastDate = date < today;
                                                    
                                                        const isNext7Days = date <= next7Days;
                                                    
                                                        return !isUnavailableDeliveryDate(date) && !isSunday && !isPastDate && !isNext7Days;
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="form-control"
                                                />
                                                {(!selectedPoojaDeliveryDate || selectedPoojaDeliveryDate.length === 0) && <p className="text-danger">Please Select a Delivery Date for Pooja Items</p>}
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }

                                </div>

                                {/* Cart Items */}

                    <div class="row">
                        <div class="ec-cart-leftside col-lg-12 col-md-12 ">
                            <div class="ec-cart-content">
                                <div class="ec-cart-inner">
                                    <div class="row">
                                        <div class="table-content cart-table-content">
                                            {cartItems.length > 0 || poojaItems.length > 0 ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th width="15%" style={{textAlign: 'right'}}>Price</th>
                                                            <th width="15%" style={{textAlign: 'center'}}>Quantity</th>
                                                            <th width="15%" style={{textAlign: 'right'}}>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {cartItems.map((items, index) => 
                                                        <tr key={index}>
                                                            <td data-label="Product" class="ec-cart-pro-name">
                                                                <a href="">
                                                                    <img class="ec-cart-pro-img mr-4" src={`https://api.vivekflowers.com/${items.img}`} alt="" />
                                                                    {items.name}
                                                                </a>
                                                            </td>
                                                            <td data-label="Price" class="ec-cart-pro-price" style={{textAlign: 'right'}}>
                                                                <span class="amount">${Number(items.price).toFixed(2)}</span>
                                                            </td>
                                                            <td data-label="Quantity" class="ec-cart-pro-price" style={{textAlign: 'center'}}>
                                                                <span class="amount">{items.qty}</span>
                                                            </td>
                                                            <td data-label="Total" class="ec-cart-pro-subtotal" style={{textAlign: 'right'}}>${Number(items.price * items.qty).toFixed(2)}</td>
                                                        </tr>
                                                    )}

                                                    {poojaItems.map((items, index) => 
                                                        <tr key={index}>
                                                            <td data-label="Product" class="ec-cart-pro-name">
                                                                <a href=""><img class="ec-cart-pro-img mr-4" src={`https://api.vivekflowers.com/${items.img}`} alt="" />
                                                                    {items.name}
                                                                </a>
                                                            </td>
                                                            <td data-label="Price" class="ec-cart-pro-price" style={{textAlign: 'right'}}>
                                                                <span class="amount">${Number(items.price).toFixed(2)}</span>
                                                            </td>
                                                            <td data-label="Quantity" class="ec-cart-pro-price" style={{textAlign: 'center'}}>
                                                                <span class="amount">{items.qty}</span>
                                                            </td>
                                                            <td data-label="Total" class="ec-cart-pro-subtotal" style={{textAlign: 'right'}}>${Number(items.price * items.qty).toFixed(2)}</td>
                                                        </tr>
                                                    )}
                                                        <tr>
                                                            <td colSpan={2} class="ec-cart-pro-subtotal"> Total</td>
                                                            <td data-label="Total Qty" class="ec-cart-pro-subtotal" style={{textAlign: 'center'}}>{parseFloat(qty)} </td>
                                                            <td data-label="Total Amount" class="ec-cart-pro-subtotal" style={{textAlign: 'right'}}> ${(parseFloat(total) + parseFloat(poojaTotal)).toFixed(2)} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                :
                                                <></>
                                            }
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="ec-cart-update-bottom">
                                                        <a href="/cart"><i className="fi-rr-arrow-left"></i> Update Cart</a>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                            </div>
                            <div class="ec-checkout-rightside col-lg-4 col-md-12">
                            
                                <div class="ec-sidebar-wrap ec-checkout-del-wrap">

                                    <div class="ec-sidebar-block">
                                        <div class="ec-sb-title">
                                            <h3 class="ec-sidebar-title"> {option === 'delivery' ?  'Delivery Method' : 'Pick up From Store'}</h3>
                                    </div>
                                    <div class="ec-sb-block-content">
                                        <div class="ec-checkout-del">
                                            <div class="ec-del-desc text-danger">{option === 'delivery' ? poojaItems.length > 0 && cartItems.length > 0 ? 'Pooja items will be shipped via ground shipping, while flowers/garlands will be sent overnight priority. Orders will be split into two shipments accordingly.' : cartItems.length > 0 ? 'Note : Flowers/Garlands items will be delivered via priority overnight.' : poojaItems.length > 0 ? 'Note : Pooja items will be delivered via ground shipping.' : '' : 'You should pick up the items from the selected store at your earliest convenience'}</div>
                                            
                                                    
                                                         {/* <div class="mb-3 mt-3"> <button type="button" class="btn btn-warning"> Pick-Up from Store Selected</button></div>
                                                    :
                                                     <div class="row">
                                                     {formData.zip !== '' &&
                                                        shipping?.rates?.slice().reverse().map((rate, index) => (
                                                         <span>
                                                             <input type="radio" id={rate.serviceType} name="shipMethod" checked={shipCharge === parseFloat(parseFloat(rate.totalNetFedExCharge.replace('$', ''))).toFixed(2) && true} value={parseFloat(parseFloat(rate.totalNetFedExCharge.replace('$', ''))).toFixed(2)} onChange={() => {handleDelivery(rate.serviceType, parseFloat(parseFloat(rate.totalNetFedExCharge.replace('$', ''))).toFixed(2))}} required />
                                                             <label for={rate.serviceType}>Rate - ${parseFloat(parseFloat(rate.totalNetFedExCharge.replace('$', ''))).toFixed(2)} - {rate.serviceType} </label>
                                                         </span>
                                                         ))
                                                     }
                                                       
                                                     </div> */}

                                
                                                    
                                                    

                                               
                                                    <span class="ec-del-commemt mb-4">
                                                        <span class="ec-del-opt-head">Add Comments About Your Order</span>
                                                        <textarea name="comment" placeholder="Comments" value={formData.comment} onChange={handleInputChange} >{formData.comment}</textarea>
                                                    </span>
                                                {option === 'delivery' &&   
                                                <>
                                                    {poojaItems.length > 0 && cartItems.length > 0 &&
                                                    <>
                                                        
                                                        
                                                        <div class="col-sm-12 ec-faq-block">
                                                            <h4 class="ec-faq-title" onClick={toggleCartVisibility}>Pooja Items 
                                                                <span>
                                                                    <p class="text-danger" style={{fontSize: '10px'}}>Note : Pooja items will be delivered within 5 to 15 working days.</p>
                                                                </span>
                                                            </h4>

                                                            <div class="ec-faq-content ec-faq-dropdown"  style={{ display: cartDetails ? 'block' : 'none' }}>
                                                                <span class="ec-del-commemt mb-4">
                                                                    <span class="ec-del-opt-head">Pooja Items</span>
                                                                    <div class="ec-sb-block-content">
                                                                        <div class="ec-checkout-summary">
                                                                            <div>
                                                                                <span class="text-left">Sub-Total</span>
                                                                                <span class="text-right">${parseFloat(poojaTotal).toFixed(2)}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span class="text-left">Delivery Charges</span>
                                                                                <span class="text-right">${parseFloat(poojaShipCharge || 0).toFixed(2) }</span>
                                                                            </div>
                                                                            <div class="ec-checkout-summary-total">
                                                                                <span class="text-left">Total Amount</span>
                                                                                <span class="text-right">${parseFloat( parseFloat(poojaTotal) + parseFloat(poojaShipCharge) ).toFixed(2)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-12 ec-faq-block">
                                                            <h4 class="ec-faq-title" onClick={togglePoojaVisibility}>Flowers & Garlands 
                                                                <span>
                                                                    <p class="text-danger" style={{fontSize: '10px'}}>Note : Flowers/Garlands will be delivered overnight on a priority basis.</p>
                                                                </span>
                                                            </h4>

                                                            <div class="ec-faq-content ec-faq-dropdown"  style={{ display: poojaDetails ? 'block' : 'none' }}>
                                                                <span class="ec-del-commemt mb-4">
                                                                    <span class="ec-del-opt-head">Flowers and garlands</span>
                                                                    <div class="ec-sb-block-content">
                                                                        <div class="ec-checkout-summary">
                                                                            <div>
                                                                                <span class="text-left">Sub-Total</span>
                                                                                <span class="text-right">${parseFloat(total).toFixed(2)}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span class="text-left">Delivery Charges</span>
                                                                                <span class="text-right">${parseFloat(shipCharge).toFixed(2) }</span>
                                                                            </div>
                                                                            <div class="ec-checkout-summary-total">
                                                                                <span class="text-left">Total Amount</span>
                                                                                <span class="text-right">${parseFloat( parseFloat(total) + parseFloat(shipCharge) ).toFixed(2)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                    </>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ec-sidebar-wrap">
                                    <div class="ec-sidebar-block">
                                        <div class="ec-sb-title">
                                            <h3 class="ec-sidebar-title">Summary</h3>
                                        </div>
                                        <div class="ec-sb-block-content">
                                            <div class="ec-checkout-summary">
                                                <div>
                                                    <span class="text-left">Sub-Total</span>
                                                    <span class="text-right">${parseFloat(total + poojaTotal).toFixed(2)}</span>
                                                </div>
                                                <div>
                                                    <span class="text-left">Delivery Charges</span>
                                                    <span class="text-right">${parseFloat( parseFloat(shipCharge) + parseFloat(poojaShipCharge) ).toFixed(2) }</span>
                                                </div>
                                                <div>
                                                    <span class="text-left">Coupan Discount</span>
                                                    <span class="text-right"><a class="ec-checkout-coupan" onClick={toggleCoupon}>Apply Coupon</a></span>
                                                </div>

                                                <div class="ec-checkout-coupan-content" style={{display: isOpen ? 'block' : 'none'}}>
                                                    <form class="ec-checkout-coupan-form">
                                                        <input class="ec-coupan" type="text" required=""
                                                            placeholder="Enter Your Coupan Code" name="ec-coupan" value="" />
                                                        <button class="ec-coupan-btn button btn-primary" type="submit"
                                                            name="subscribe" value="">Apply</button>
                                                    </form>
                                                </div>

                                                <div class="ec-checkout-summary-total">
                                                    <span class="text-left">Total Amount</span>
                                                    <span class="text-right">${parseFloat(grandTotal).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>
                                
                                <div class="ec-sidebar-wrap ec-checkout-pay-wrap">
                                    <div class="ec-sidebar-block">
                                        <div class="ec-sb-title">
                                            <h3 class="ec-sidebar-title">Payment</h3>
                                        </div>

                                        <span class="ec-check-order-btn text-center">
                                            {paymentButton ? poojaShipCharge !== 0 || shipCharge !== 0  || option === 'pickup' ? selectedDeliveryDate === ''   ? 
                                                <>
                                                   <p class="text-danger">Missing Required Details!</p>  
                                                </>      
                                            :   
                                            <>
                                                <button type="button" class="btn btn-primary w-100 mt-4" href="#" onClick={placeOrder}>Pay with Credit/Debit Card</button> 
                                                <button type="submit" class="btn btn-warning w-100 mt-2 mb-4" href="#">Pay with Paypal</button> 
                                            </>
                                            :   <>
                                                    <p class="text-danger ">Missing Required Details!</p>  
                                                </>                       
                                            :   <button type="button" class="btn btn-secondary w-100 mt-4 mb-4" href="#">Processing</button>
                                            }
                                        </span>

                                    </div>
                                </div> 

                            </div>
                       
                        </div>
                    </form>
                    :
                        <div className="row">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                                <div style={{ textAlign: 'center' }}>
                                    Your cart is Empty, Please add items!<br />
                                    <a href="/" class="btn btn-primary mt-3">Shop Now</a>
                                </div>
                            </div>
                        </div>
                       
                    }
                    </div>
                </section >

            </div >



            <Footer />



        </>
    )
}


export default CheckOut;