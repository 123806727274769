import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Flower from './flower';
import "./style.css";

const ProductCategories = () => {
    const [products, setProducts] = useState([]);
    const [subcategory_id, setsubcategory_id] = useState('');
    const [loader, setLoader] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoader(false);
        }, 1500);
        return () => clearInterval(intervalId);
    }, []);

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    useEffect(() => {
        if (location.state && location.state.products) {
            const updatedProducts = location.state.products.map((product) => ({
                ...product,
                quantity: 1,
                selectedVariation: null,
            }));
            setProducts(updatedProducts);
        }
    }, [location]);

    useEffect(() => {
        const category = getQueryParam('id');
        setsubcategory_id(category);
    }, []);

    const fetchProducts = async (cat_id) => {
        try {
            const response = await axios.get(`https://api.vivekflowers.com/api/products-with-variations/subcategory?name=${cat_id}`);
            if (response.data) {
                const updatedProducts = response.data.products.map((product) => ({
                    ...product,
                    quantity: 1,
                    selectedVariation: null,
                }));
                setProducts(updatedProducts);
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching products:", error.message);
        }
    };

    useEffect(() => {
        const category = getQueryParam('id');
        fetchProducts(category);
        setsubcategory_id(category);
    }, [subcategory_id]);

    const handleIncreaseQty = (index) => {
        const updatedProducts = [...products];
        updatedProducts[index].quantity += 1 || 1;
        setProducts(updatedProducts);
    };

    const handleDecreaseQty = (index) => {
        const updatedProducts = [...products];
        if (updatedProducts[index].quantity > 1) {
            updatedProducts[index].quantity -= 1;
            setProducts(updatedProducts);
        }
    };

    const handleVariationChange = (productIndex, variationId) => {
        const updatedProducts = [...products];
        const selectedProduct = updatedProducts[productIndex];
        const selectedVariation = selectedProduct.variations.find(variation => variation.variation_id === variationId);
        updatedProducts[productIndex].selectedVariation = selectedVariation;

        setProducts(updatedProducts);
    };

    const addCart = (pId, qty) => {
        const selectedProduct = products.find(item => item.p_id === pId);
        const variation = selectedProduct.selectedVariation;
        const cartKey = selectedProduct.category_id === 2 && ( selectedProduct.subcategory_id === 1 || selectedProduct.subcategory_id === 2 ) ? 'poojaItems' : 'cartItems';
        const pName = variation ? variation.variation_name :  selectedProduct.productName;

        const existingCartItems = JSON.parse(sessionStorage.getItem(cartKey)) || [];
        const existingItemIndex = existingCartItems.findIndex(item => item.productId === pId && item.name === pName);

        if (existingItemIndex !== -1) {
            const newQty = existingCartItems[existingItemIndex].qty + qty;
            if (newQty > 0) {
                existingCartItems[existingItemIndex].qty = newQty;
            }
        } else {
            const newItem = {
                productId: selectedProduct.p_id,
                qty: qty,
                price: variation ? variation.variation_amount : selectedProduct.amount,
                name: variation ? variation.variation_name :  selectedProduct.productName,
                img: selectedProduct.image_url,
                weight: variation ? variation.weight : selectedProduct.weight,
                iceWeight: variation ? variation.withIceWeight : selectedProduct.weightWithIce,
            };
            existingCartItems.push(newItem);
        }

        sessionStorage.setItem(cartKey, JSON.stringify(existingCartItems));

        // Toast notification logic
        const addToCartToast = document.getElementById("addtocart_toast");
        const spanElement = addToCartToast.querySelector(".desc span");
        spanElement.textContent = variation ? variation.variation_name :  selectedProduct.productName;

        addToCartToast.classList.add("show");

        setTimeout(() => {
            addToCartToast.classList.remove("show");
        }, 3000);
    };


    const handleAddToWishlist = async (productId, variationId) => {
        const cust_id = sessionStorage.getItem('VVKuserId'); 
    
        if (!cust_id) {
            navigate("/sign-in", { state: { from: location } });
            return;
        }
    
        if (!productId) {
            alert('Invalid product selection.');
            return;
        }
    
       
            const response = await axios.post('https://api.vivekflowers.com/api/wishlist', {
                cust_id,
                product_id: productId,
                variation_id: variationId || null,
            });

            if (response.data.status === 200) {
                // Show success toast for wishlist
                const wishlistToast = document.getElementById("wishlist_toast");
                const spanElement = wishlistToast.querySelector(".desc span");
                spanElement.textContent = 'Product added to wishlist successfully!';
                wishlistToast.classList.add("show");

                setTimeout(() => {
                    wishlistToast.classList.remove("show");
                }, 3000);
            } else {
                alert(response.data?.message || 'Failed to add product to wishlist.');
            }
       
    };

   // console.log(products);

    return (
        <>{loader && <Flower /> }
            <div>
                <Header />
                <div className="sticky-header-next-sec ec-breadcrumb section-space-mb">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row ec_breadcrumb_inner">
                                    <div className="col-md-6 col-sm-12">
                                        <h2 className="ec-breadcrumb-title">{subcategory_id}</h2>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <ul className="ec-breadcrumb-list">
                                            <li className="ec-breadcrumb-item">
                                                <Link to="/home">Home</Link>
                                            </li>
                                            <li className="ec-breadcrumb-item active">{subcategory_id}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shop-pro-content container">
                    <div className="shop-pro-inner">
                        <div className="row">
                            {products.length > 0 ? (
                                products.map((product, index) => (
                                    <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6 mb-6 col-12 pro-gl-content" key={index}>
                                        <div className="ec-product-inner" style={{ border: 'solid 1px lightblue' }}>
                                            
                                                <div className="ec-pro-image-outer">
                                                    <div className="ec-pro-image">
                                                        <a href={`/product?id=${product.p_id}`} className="image">
                                                            <img
                                                                src={`https://api.vivekflowers.com/${product.image_url}`}
                                                                alt={product.name}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </a>
                                                        <div className="ec-pro-actions">
                                                            <button className="ec-btn-group wishlist" onClick={() => handleAddToWishlist(product.p_id, product.selectedVariation?.variation_id)} title="Wishlist">
                                                                <i className="fi-rr-heart"></i>
                                                            </button>
                                                            <a href={`/product?id=${product.p_id}`} className="add-to-cart" title="View Product">
                                                                <i className="fi-rr-shopping-basket"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            <div className="ec-pro-content" style={{ padding: '10px' }}>
                                                <h5 className="ec-pro-title">
                                                    <a href={`/product?id=${product.p_id}`}>{product.productName || "Product Name"}</a>
                                                </h5>
                                                <span className="ec-price">
                                                    {product.amount && parseFloat(product.amount) > 0 &&
                                                        <span className="new-price">${Number(product.selectedVariation ? product.selectedVariation.variation_amount : product.amount).toFixed(2)}</span>
                                                    }
                                                </span>
                                                {product.variations && product.variations.length > 0 && (
                                                    <select
                                                        className="form-control border border-primary my-3"
                                                        onChange={(e) => handleVariationChange(index, parseInt(e.target.value))}
                                                        value={product.selectedVariation ? product.selectedVariation.variation_id : ''}
                                                    >
                                                        <option value="" disabled>Select Variation</option>
                                                        {product.variations.map(variation => (
                                                            <option key={variation.variation_id} value={variation.variation_id}>
                                                                {variation.variation_name} {parseFloat(variation.variation_amount) > 0 &&  `- $ ${variation.variation_amount}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                                <span className="ec-price pricing-sec" >
                                                    {product.amount && parseFloat(product.amount) > 0 ? (
                                                       product.variations && product.variations.length > 0 ? 
                                                            product.selectedVariation && product.selectedVariation !== null?
                                                            <>
                                                                <div className="qty-plus-minus" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <button className="qty-btn" onClick={() => handleDecreaseQty(index)}>-</button>
                                                                    <input className="qty-input" type="text" value={product.quantity} readOnly />
                                                                    <button className="qty-btn" onClick={() => handleIncreaseQty(index)}>+</button>
                                                                </div>
                                                           
                                                                <div style={{ float: 'right' }}>
                                                                    <button className="btn btn-primary" onClick={() => addCart(product.p_id, product.quantity, product.productName)}>
                                                                        Add to Cart
                                                                    </button>
                                                                </div>
                                                            </>
                                                            : <small style={{marginLeft: '5px', fontWeight: '500', color: 'darkred'}}>Please Select a Variation</small>
                                                            :
                                                            <>
                                                                <div className="qty-plus-minus" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <button className="qty-btn" onClick={() => handleDecreaseQty(index)}>-</button>
                                                                        <input className="qty-input" type="text" value={product.quantity} readOnly />
                                                                    <button className="qty-btn" onClick={() => handleIncreaseQty(index)}>+</button>
                                                                </div>
                                                                <div style={{ float: 'right' }}>
                                                                    <button className="btn btn-primary" onClick={() => addCart(product.p_id, product.quantity, product.productName)}>
                                                                        Add to Cart
                                                                    </button>
                                                                </div>
                                                            </>
                                                            
                                                        
                                                    ) : (
                                                        <span className="new-price text-danger">Call us 848 200 5992</span>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12 text-center">
                                    <h4>No Products Available</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div id="addtocart_toast" className="addtocart_toast">
                    <div className="desc"><span> </span> Added To Cart</div>
                </div>

                <div id="wishlist_toast" className="addtocart_toast">
                    <div className="desc"><span> </span> </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default ProductCategories;
