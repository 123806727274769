import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Flower from './flower';

function formatNumber(number, length) {
    return String(number).padStart(length, '0');
}

const App = () => {

    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [orderId, setOrderId] = useState('');
    const [orderDatas, setOrderData] = useState(false);
    const [status, setStatus] = useState('Loading');

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };
    
    useEffect(() => {
        const id = getQueryParam('token');
        setOrderId(id);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoader(false);
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(!orderDatas){
                window.location.reload();
            }
        }, 3000);
        return () => clearInterval(intervalId);
    }, [orderDatas]);

    const getOrder = async () => {

        try{
            const response = await axios.post(`https://api.vivekflowers.com/api/status/${orderId}`);
            //console.log(response.data);
           
            setStatus(response.data[0].paymentStatus);
            setOrderData(response.data);

        }catch(error){
            console.error(error);
        }
    };

    useEffect(() => {
        getOrder();
        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('poojaItems');
    },[orderId]);


    return (
        

        <>
        
        {loader && <Flower /> }
            <Header />

            {/* breadcrumb */}

            <div class="sticky-header-next-sec  ec-breadcrumb section-space-mb">

                <div class="container">

                    <div class="row">

                        <div class="col-12">

                            <div class="row ec_breadcrumb_inner">

                                <div class="col-md-6 col-sm-12">

                                    <h2 class="ec-breadcrumb-title">{orderDatas.length} Orders</h2>

                                </div>

                                <div class="col-md-6 col-sm-12">

                                    <ul class="ec-breadcrumb-list">

                                        <li class="ec-breadcrumb-item"><a href="/">Home</a></li>

                                        <li class="ec-breadcrumb-item active">Payment Process</li>

                                    </ul>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* end of the breadcrumb */}


            <section class="ec-page-content section-space-p">
                <div class="container">

                    <div className="row">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                            <div style={{ textAlign: 'center' }}>

                                <img src={  status === 'Loading' ? 
                                                `assets/img/icons/loader.gif` : 
                                            status === 'PAYER_ACTION_REQUIRED'  ?
                                                `assets/img/icons/failed.webp` : 
                                            status === 'not_paid' ?
                                                `assets/img/icons/failed.webp` : 
                                            status === 'APPROVED' ? 
                                                `assets/img/icons/failed.webp` :
                                            status === 'COMPLETED' ? 
                                                `assets/img/icons/processed.gif`
                                             :
                                                `assets/img/icons/failed.webp`
                                        } 
                                width="300"/>

                                <h3> {  status === 'Loading' ? 
                                            `Checking Payment Status` : 
                                        status === 'PAYER_ACTION_REQUIRED' ?
                                            `Your payment has not yet been completed!` : 
                                        status === 'not_paid' ?
                                            `Unable to fetch payment status!` : 
                                        status === 'APPROVED' ?
                                            `Check payment status again!` : 
                                        status === 'COMPLETED' ? 
                                            `Payment Successfull` 
                                        :
                                            `Payment Failed`
                                     }
                                </h3>
                                {  status === 'Loading' && <p class="text-danger">Please wait & Do not close or Reload this window</p> }

                                {(status === 'Payment Initiated' || status === 'PAYER_ACTION_REQUIRED')  && <>
                                    <a  href={`https://www.paypal.com/checkoutnow?token=${orderId}`}class="btn btn-success mt-4 mb-4">Pay Now</a>
                                    <button onClick={()=>window.location.reload()}  class="btn btn-secondary ml-2 mt-4 mb-4">Check Payment Status</button>
                                    </>
                                }

                                {(status === 'APPROVED' || status === 'not_paid')  &&
                                    <button onClick={()=>window.location.reload()}  class="btn btn-success mt-4 mb-4">Check Payment Status</button>
                                }
                                
                                    
                            </div>
                        </div>
                    </div>
                  { status === 'COMPLETED' ?

                orderDatas && orderDatas.map((orderData, index) =>
                <>
                    <section class="section ec-services-section section-space-p" id="services">
                        <h2 class="d-none">Services</h2>
                            <div class="container">
                                <div class="row">
                                    <div class="ec_ser_content ec_ser_content_1 col-sm-12 col-md-6 col-lg-3 zoomIn" data-animation="zoomIn" data-animated="true">
                                        <div class="ec_ser_inner">
                                            <div class="ec-service-image">
                                            <i class="fi fi-ts-hand-holding-seeding"></i>
                                            </div>
                                            <div class="ec-service-desc">
                                                <h2>Order Details</h2>
                                                <p>
                                                    Order Id : {formatNumber(orderData.invNo, 7)} <br />
                                                    Order Date : {orderData.date} <br /> <br />
                                                    Email : {orderData.email} <br />
                                                    Contact : {orderData.phone} <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ec_ser_content ec_ser_content_2 col-sm-12 col-md-6 col-lg-3 zoomIn" data-animation="zoomIn" data-animated="true">
                                        <div class="ec_ser_inner">
                                            <div class="ec-service-image">
                                                <i class="fi fi-ts-truck-moving"></i>
                                            </div>
                                            <div class="ec-service-desc">
                                                <h2>{orderData.orderType === 'pickup' ? 'Picked up from' : 'Address'}</h2>
                                                
                                                {orderData.orderType === 'pickup' ? 
                                                    <p>{orderData.address1}</p>
                                                :
                                                    <p>
                                                        <b>{orderData.fName} {orderData.lName} </b><br />
                                                        {orderData.address1}, {orderData.address2},{orderData.city}, {orderData.state}, {orderData.country} - {orderData.zip} 
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ec_ser_content ec_ser_content_3 col-sm-12 col-md-6 col-lg-3 zoomIn" data-animation="zoomIn" data-animated="true">
                                        <div class="ec_ser_inner">
                                            <div class="ec-service-image">
                                                <i class="fi fi-ts-badge-percent"></i>
                                            </div>
                                            <div class="ec-service-desc">
                                                <h2>Order Type</h2>
                                                <p>
                                                   Type:  {orderData.orderType} <br />
                                                   Date : {orderData.deliveryDate}

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ec_ser_content ec_ser_content_4 col-sm-12 col-md-6 col-lg-3 zoomIn" data-animation="zoomIn" data-animated="true">
                                        <div class="ec_ser_inner">
                                            <div class="ec-service-image">
                                                <i class="fi fi-ts-donate"></i>
                                            </div>
                                            <div class="ec-service-desc">
                                                <h2>Order Value</h2>
                                                <p>
                                                    Sub Total : ${Number(orderData.total).toFixed(2)} <br />
                                                    Shipping : ${Number(orderData.shipping).toFixed(2)} <br />
                                                    Grand Total : ${Number(orderData.grandTotal).toFixed(2)} <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        
                        <div class="row">
                        <div class="ec-cart-leftside col-lg-8 col-md-12 ">
                            <div class="ec-cart-content">
                                <div class="ec-cart-inner">
                                    <div class="row">
                                            <div class="table-content cart-table-content">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Price</th>
                                                            <th style={{textAlign: 'center'}}>Quantity</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {orderData?.orderData?.map((items, index) => 
                                                        <tr>
                                                            <td data-label="Product" class="ec-cart-pro-name">
                                                                <img class="ec-cart-pro-img mr-4" src={`https://api.vivekflowers.com/${items.img}`} alt="image" />
                                                                {items.name}
                                                            </td>
                                                            <td data-label="Price" class="ec-cart-pro-price"><span
                                                                class="amount">${Number(items.price).toFixed(2)}</span></td>
                                                            <td data-label="Quantity" class="ec-cart-pro-qty" style={{textAlign: 'center'}}>
                                                               {items.qty}
                                                            </td>
                                                            <td data-label="Total" class="ec-cart-pro-subtotal">${Number(items.price * items.qty).toFixed(2)}</td>
                                                            
                                                        </tr>
                                                    )}

                                                       
                                                       
                                                    </tbody>
                                                </table>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ec-cart-rightside col-lg-4 col-md-12">
                            <div class="ec-sidebar-wrap">
                                <div class="ec-sidebar-block">
                                    <div class="ec-sb-title">
                                        <h3 class="ec-sidebar-title">Summary</h3>
                                    </div>
                                    <div class="ec-sb-block-content">
                                        <div class="ec-cart-summary-bottom">
                                            <div class="ec-cart-summary">
                                                <div>
                                                    <span class="text-left">Sub-Total</span>
                                                    <span class="text-right">${Number(orderData.total).toFixed(2)}</span>
                                                </div>
                                                <div>
                                                    <span class="text-left">Shipping Charge</span>
                                                    <span class="text-right">${Number(orderData.shipping).toFixed(2)}</span>
                                                </div>
                                                <div>
                                                    <span class="text-left">Coupon Discount</span>
                                                    <span class="text-right">$00.00</span>
                                                </div>
                                                <div class="ec-cart-summary-total">
                                                    <span class="text-left">Total Amount</span>
                                                    <span class="text-right">${Number(orderData.grandTotal).toFixed(2)}</span>
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )
                :
                <></>
            }
                
                        
                   

                </div>
            </section>




<Footer/>


        </>
    )
}
export default App;