import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";


const SignUp = () => {

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    address: "",
    address_line2: "",
    city: "",
    state: "",
    postalcode: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(
          "https://api.vivekflowers.com/api/cust-register",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          const RegisterToast = document.getElementById("register_toast");
          RegisterToast.classList.add("show");
            setTimeout(() => {
              RegisterToast.classList.remove("show");
              navigate("/sign-in");
            }, 3000);

          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            address: "",
            address_line2: "",
            city: "",
            state: "",
            postalcode: "",
            phone: "",
          });

        } else {
          const errorData = await response.json();
          setApiError(
            errorData.message || "Failed to register. Please try again."
          );
        }
      } catch (error) {
        setApiError("An error occurred. Please try again later.");
        console.log('An error occurred. Please try again later');
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /\S+@\S+\.\S+/;
    const phoneRegex = /^\d{10}$/;

    if (!formData.first_name) newErrors.first_name = "First Name is required.";
    if (!formData.last_name) newErrors.last_name = "Last Name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    else if (!emailRegex.test(formData.email))
      newErrors.email = "Email is invalid.";

    if (!formData.password) newErrors.password = "Password is required.";
    else if (formData.password.length < 6)
      newErrors.password = "Password should be at least 6 characters.";

    if (!formData.phone) newErrors.phone = "Phone number is required.";
    else if (!phoneRegex.test(formData.phone))
      newErrors.phone = "Phone Number must be 10 digits.";

    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.postalcode) newErrors.postalcode = "Postal code is required.";

    return newErrors;
  };

  return (
    <>
      <Header />

      {/* breadcrumb */}
      <div className="sticky-header-next-sec ec-breadcrumb section-space-mb">

        <div className="container">

          <div className="row">

            <div className="col-12">

              <div className="row ec_breadcrumb_inner">

                <div className="col-md-6 col-sm-12">


                  <h2 className="ec-breadcrumb-title">Register</h2>

                </div>


                <div className="col-md-6 col-sm-12">

                  <ul className="ec-breadcrumb-list">

                    <li className="ec-breadcrumb-item">

                      <a href="/home">Home</a>

                    </li>

                    <li className="ec-breadcrumb-item active">Register</li>

                  </ul>

                </div>

              </div>

            </div>

          </div>


        </div>

      </div>


      {/* end of the breadcrumb */}

      {/* sign up */}
      <section className="ec-page-content section-space-p">


        <div className="container">

          <div className="row">

            <div className="col-md-12 text-center">

              <div className="section-title">

                <h2 className="ec-title">Create an Account</h2>

              </div>

            </div>

            <div className="ec-register-wrapper">
              <div className="ec-register-container">
                <div className="ec-register-form">
                  <form onSubmit={handleSubmit} method="post">
                    {/* First Name and Last Name */}
                    <div className="row mt-3">

                      {/* first name */}
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="ec-register-form">First Name</label>
                        <input type="text" name="first_name" className="form-control" placeholder="Enter your First name" value={formData.first_name} onChange={handleChange} />

                      </div>
                      {/* end of the firstname */}

                      {/* lastname */}
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="ec-register-form">Last Name</label>
                        <input type="text" name="last_name" className="form-control" placeholder="Enter your Last name" value={formData.last_name} onChange={handleChange} />
                      </div>
                      {/* end of the lastname */}

                      {/* email */}
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="ec-register-form">Email</label>
                        <input name="email" className="form-control" placeholder="Email" value={formData.email} onChange={handleChange} />
                      </div>
                      {/* end of the email */}
                      {/* password */}
                      <div className="col-lg-6 col-12 mt-2">
                        <label className="ec-register-form">Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </div>
                      {/* end of the password */}

                      {/* phone */}
                      <div className="col-lg-6 col-12 mt-2">
                        <label>Phone Number</label>
                        <input type="number" name="phone" className="form-control" placeholder="Enter your phone number" value={formData.phone} onChange={handleChange} />
                      </div>

                      {/* end of the phone */}
                      <div className="col-lg-6 col-12">
                        <label>Address</label>
                        <input type="text" name="address" className="form-control" placeholder="Address" value={formData.address} onChange={handleChange} />

                      </div>
                      <div className="col-lg-6 col-12">
                        <label>Address 2</label>
                        <input type="text" name="address_line2" className="form-control" placeholder="Address Line 2" value={formData.address_line2} onChange={handleChange} />
                      </div>


                      {/* City and Post Code */}
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label>City</label>
                          <input type="text" name="city" className="form-control" placeholder="City" value={formData.city} onChange={handleChange} />
                        </div>
                        <div className="col-lg-4 col-12">
                          <label className="ec-register-form">Post Code</label>
                          <input type="text" name="postalcode" className="form-control" placeholder="Post Code" value={formData.postalcode} onChange={handleChange} />
                        </div>

                        <div class="col-lg-4 col-12">
                          <label htmlFor="state">State</label>
                          <select name="state" class="form-select" value={formData.state} onChange={handleChange}>
                            <option value="" hidden>Select State</option>
                            <option value="AL">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="District Of Columbia">District Of Columbia</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="MinnesotaN">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">North Carolina</option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="OKlahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennysylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">South Carolina</option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                          </select>

                        </div>
                      </div>
                    </div>
                    <span class="ec-register-wrap ec-register-btn">
                      <div className="text-center">
                        <a href=""><button
                          className="btn btn-primary"
                          type="submit"
                          style={{ borderRadius: "10px", width: "50%" }}
                        >
                          Register
                        </button></a>
                      </div>
                      {/* </div> */}
                    </span>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* sign up end */}

      <Footer />

      <div id="register_toast" class="register_toast" >
        <div class="desc">Register Successfully</div>
      </div >
    </>
  );
};

export default SignUp;
