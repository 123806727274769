import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paginator } from 'primereact/paginator';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import './style.css';
import Flower from './flower';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(12);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const onPageChange = (event) => {
    setFirst(event.first);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const custId = location.state?.cust_id || sessionStorage.getItem("VVKuserId");
      if (!custId) {
        setError("User not logged in.");
        navigate('/sign-in')
        return;
      }

      try {
        const response = await axios.get(`https://api.vivekflowers.com/api/customer-orders?custrId=${custId}`);
        setOrders(response.data);
        setLoader(false);
      } catch (error) {
        setError("Failed to fetch orders. Please try again later.");
      }
    };

    fetchOrders();
  }, [location.state, navigate]);

  useEffect(() => {
          const intervalId = setInterval(() => {
              setLoader(false);
          }, 5000);
          return () => clearInterval(intervalId);
      }, []);

  return (
    <>{loader && <Flower /> }
      <Header />

      {/* Breadcrumb */}
      <div className="sticky-header-next-sec ec-breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row ec_breadcrumb_inner">
                <div className="col-md-6 col-sm-12">
                  <h2 className="ec-breadcrumb-title">My Orders</h2>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul className="ec-breadcrumb-list">
                    <li className="ec-breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="ec-breadcrumb-item active">Orders</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Orders */}
      <div className="shop-pro-content container mt-4">
        <div className="shop-pro-inner">
          <div className="row mt-4">
              {error && (
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              )}
              {!error && orders.length === 0 && (
                <p style={{ textAlign: "center" }}>No orders found.</p>
              )}
              {orders.length > 0 && (orders.slice().reverse().slice(first, first + rows).map((order, index) => 
                <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6 mb-6 col-12 pro-gl-content" key={index}>
                  <div className="ec-product-inner" style={{ border: 'solid 1px lightblue', borderRadius: '10px', padding: '20px' }}>
                  <div className="order-card">
                    
                    <p class="text-center"><strong className="fw-bold">{order.orderType === 'delivery' ? 'Delivery' : 'Store Pickup'}</strong></p>
                    <p class="text-center">{order.orderId}</p>
                    <p><strong className="fw-bold">{order.fName} {order.lName}</strong><br />
                    {order.orderType === 'delivery' && `${order.address1}, ${order.address2}, ${order.city}, ${order.state}, ${order.country} - ${order.zip}` }</p>
                    <p><strong className="fw-bold"><i className="fi-rr-envelope"></i></strong> {order.email}</p>
                    <p><strong className="fw-bold"><i className="fi-rr-mobile"></i></strong> {order.phone}</p>
                    <p><strong className="fw-bold">Items:</strong> {order.items}</p>
                    <p><strong className="fw-bold">Total:</strong> ${Number(order.grandTotal).toFixed(2)}</p>
                    <div align="center">
                      <button class={`btn ${order.status === 'Cancelled' ? 'btn-secondary' : order.status === 'delivered' ? 'btn-success' : order.status === 'Order Placed' ? 'btn-info' : 'btn-warning'}`}>{order.status}</button>
                    </div>
                    
                  </div>
                </div>
                </div>
                ))}

                <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={orders.length}  onPageChange={onPageChange} />
            
            </div>
          </div>
        </div>

      <Footer />
    </>
  );
};

export default Orders;
