import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Customer = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const custId = query.get("id");
    const [customer, setCustomer] = useState(null);
    const [formData, setFormData] = useState({
        cust_id: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        address_line2: "",
        postalcode: "",
        state: "",
        city: "",
        country: ""
    });
    const [error, setError] = useState(null);

    const getZip = async (zip) => {
        try {
            const response = await axios.post(`https://api.vivekflowers.com/api/zipcode/${zip}`);
            
            if (response.data.status && response.data.status === 'error') {
                
            } else {
                if (response.data[0]) {
                    setFormData((prevData) => ({
                        ...prevData,
                        city: response.data[0].city,
                        state: response.data[0].stateName,
                        country: response.data[0].CountryName
                    }));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await fetch(`https://api.vivekflowers.com/api/customer/${custId}`);
                if (!response.ok) {
                    throw new Error("Customer not found");
                }
                const data = await response.json();
                setCustomer(data);
                setFormData(data); 
            } catch (err) {
                setError(err.message);
            } finally {
                //setLoading(false);
            }
        };

        if (custId) {
            fetchCustomer();
        }
    }, [custId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'postalcode') {
            getZip(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        axios.put(`https://api.vivekflowers.com/api/customer/${custId}`, formData)
            .then((response) => {
                setCustomer(response.data.updated_data); 
                alert("Profile updated successfully!");
            })
            .catch((error) => {
                console.error("Error updating data:", error);
                alert("Failed to update profile. Please try again.");
            });
    };

    return (
        <>
            <Header />
            <div className="sticky-header-next-sec ec-breadcrumb section-space-mb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row ec_breadcrumb_inner">
                                <div className="col-md-6 col-sm-12">
                                    <h2 className="ec-breadcrumb-title">User Profile</h2>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <ul className="ec-breadcrumb-list">
                                        <li className="ec-breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="ec-breadcrumb-item active">Profile</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="ec-page-content ec-vendor-uploads ec-user-account section-space-p">
                <div className="container">
                    <div className="row">
                        <div className="ec-shop-leftside ec-vendor-sidebar col-lg-3 col-md-12">
                            <div className="ec-sidebar-wrap ec-border-box">
                                <div className="ec-sidebar-block">
                                    <div className="ec-vendor-block">
                                        <div className="ec-vendor-block-bg"></div>
                                        <div className="ec-vendor-block-detail">
                                            <img className="v-img" src="assets/images/icons/user.webp" alt="vendor image" />
                                            <h5>{customer?.first_name} {customer?.last_name}</h5>
                                        </div>
                                        <div className="ec-vendor-block-items">
                                            <ul>
                                                <li><a href="#users">User Profile</a></li>
                                                <li><a href="/wishlist">Wishlist</a></li>
                                                <li><a href="/cart">Cart</a></li>
                                                <li><a href="/my-orders">Orders</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ec-shop-rightside col-lg-9 col-md-12" id="#users">
                            <div className="ec-vendor-dashboard-card ec-vendor-setting-card">
                                <div className="ec-vendor-card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="ec-vendor-block-profile">
                                                <div className="ec-vendor-block-img space-bottom-30">
                                                    <div className="ec-vendor-block-bg">
                                                        <a href="#" className="btn btn-lg btn-primary"
                                                            data-link-action="editmodal" title="Edit Detail"
                                                            data-bs-toggle="modal" data-bs-target="#edit_modal">Edit Detail</a>
                                                    </div>

                                                    <div className="ec-vendor-block-detail">
                                                        <img className="v-img" src="assets/images/icons/user.webp" alt="vendor image" />
                                                        <h5 className="name">{customer?.first_name} {customer?.last_name}</h5>
                                                    </div>
                                                    <p>Hello <span>{customer?.first_name} {customer?.last_name}</span></p>
                                                    <p>From your account you can easily view and track orders. You can manage and change your account information like address, contact information and history of orders.</p>
                                                </div>
                                                <h5>Account Information</h5>

                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="ec-vendor-detail-block ec-vendor-block-email space-bottom-30">
                                                            <ul>
                                                                <li><strong>Email  : </strong>{customer?.email}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="ec-vendor-detail-block ec-vendor-block-contact space-bottom-30">
                                                            <ul>
                                                                <li><strong>Phone Number : </strong>{customer?.phone}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="ec-vendor-detail-block ec-vendor-block-address mar-b-30">
                                                            <h6>Address<a href="javasript:void(0)" data-link-action="editmodal" title="Edit Detail" data-bs-toggle="modal" data-bs-target="#edit_modal"><i className="fi-rr-edit"></i></a></h6>
                                                            <ul>
                                                                <li><strong>Address : </strong>{customer?.address}</li>
                                                                <li><strong>Address Line 2 : </strong>{customer?.address_line2}</li>
                                                                <li><strong>City : </strong>{customer?.city}</li>
                                                                <li><strong>State : </strong>{customer?.state}</li>
                                                                <li><strong>Postal Code : </strong>{customer?.postalcode}</li>
                                                                <li><strong>Country : </strong>{customer?.country}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="edit_modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="ec-vendor-upload-detail">
                                    <h5>Edit the Customer Details</h5>
                                    <form className="row g-3" onSubmit={handleSubmit}>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">First name</label>
                                            <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">Last name</label>
                                            <input type="text" className="form-control" name="last_name" value={formData.last_name} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-12 space-t-15">
                                            <label className="form-label">Address 1</label>
                                            <input type="text" className="form-control" name="address" value={formData.address} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-12 space-t-15">
                                            <label className="form-label">Address 2</label>
                                            <input type="text" className="form-control" name="address_line2" value={formData.address_line2} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="for m-label">Postal Code</label>
                                            <input type="text" className="form-control" name="postalcode" value={formData.postalcode} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">City</label>
                                            <input type="text" className="form-control" name="city" value={formData.city} onChange={handleChange} disabled />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">State</label>
                                            <input type="text" className="form-control" name="state" value={formData.state} onChange={handleChange} disabled />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">Country</label>
                                            <input type="text" className="form-control" name="country" value={formData.country} onChange={handleChange} disabled />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">Email</label>
                                            <input type="text" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 space-t-15">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleChange} />
                                        </div>
                                        
                                        <div className="col-md-12 space-t-15 mt-4" align="right">
                                            <button type="submit" className="btn btn-primary">Update</button>
                                            <a href="#" className="btn btn-lg btn-secondary qty_close" data-bs-dismiss="modal" aria-label="Close">Close</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Customer;