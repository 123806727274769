import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../components/style.css';

const Location = () => {

    return (
        <>
            <Header />
            <section class="section ec-category-section ec-category-wrapper-4 section-space-p">

                <div class="container">
                    <div className="row">
                        {/* New Jersey section */}
                        <div className="col-lg-12 mt-4" align="center">
                            <div style={{ background: '#257fb4', padding: '10px' }}>
                                <h2 className='text-white fw-bold'>New Jersey</h2>
                            </div>
                        </div>



                        {/* South Brunswick */}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/7zDRDDT7cx2bHTgw8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            South Brunswick</a></h6>
                                    <p className="text-left text-gray-500 mb-1">3158 NJ-27,</p>
                                    <p className="text-left text-gray-500 mb-1">Kendall Park, NJ 08823</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 732-419-3894</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>

                        {/* Jersey City */}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/yANZpE5UzNdczPJW8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold  text-decoration-none ">
                                            Jersey City</a></h6>
                                    <p className="text-left text-gray-500 mb-1">789 Newark Avenue,</p>
                                    <p className="text-left text-gray-500 mb-1">Jersey city, NJ 07306</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 201-918-5229</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:30 pm</p>
                                </div>
                            </div>
                        </div>

                        {/* East Windsor */}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/Goeo9oQXAA7sEQpGA"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            East Windsor
                                        </a>
                                    </h6>

                                    <p className="text-left text-gray-500 mb-1">East Windsor- NJ 553 US</p>
                                    <p className="text-left text-gray-500 mb-1">Highway 130 East Windsor, NJ 08520</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 609-308-2421 & 732-850-6402</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 8:00 pm</p>
                                </div>
                            </div>
                        </div>

                        {/* Piscataway */}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/85KjxNJ3XyBJuS347"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Piscataway</a></h6>
                                    <p className="text-left text-gray-500 mb-1">No 1410 Stelton Road,</p>
                                    <p className="text-left text-gray-500 mb-1">Piscataway, NJ 08854</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 732-662-9255</p>
                                    <p className="text-left text-gray-500 mb-1">Mon - Tue : 10:00 am - 9:30 pm</p>
                                    <p className="text-left text-gray-500">Wed - Sun : 9:00 am - 9:30 pm</p>
                                </div>
                            </div>
                        </div>

                        {/* Edison */}
                        <div className="col-lg-3  mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/a5XiniXSQrRfc2mL9"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Edison</a></h6>
                                    <p className="text-left text-gray-500 mb-1">1734 Oak Tree Road, Unit 6,</p>
                                    <p className="text-left text-gray-500 mb-1">Edison, NJ 08820</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 732-321-0403 & 732-589-5143</p>
                                    <p className="text-left text-gray-500 mb-1">Mon - Tue : 10:00 am - 9:30 pm</p>
                                    <p className="text-left text-gray-500">Wed - Sun : 9:00 am - 9:30 pm</p>
                                </div>
                            </div>
                        </div>

                        {/* Guruvaayoorappan Temple */}
                        <div className="col-lg-3  mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img">
                                    <h6 className="text-left text-xl font-semibold text-gray-700">
                                        <a href="https://maps.app.goo.gl/SsF729455SVBYuKQ7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Guruvaayoorappan Temple Gift Store (Morganville)</a></h6>
                                    <p className="text-left text-gray-500 mb-1">31 Wooleytown Rd,Morganville, NJ 07751</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 732-970-5671</p>
                                    <p className="text-left text-gray-500 mb-1">Mon - Fri : 8:30 am - 11:30 am</p>
                                    <p className="text-left text-gray-500 mb-1">Mon - Fri : 4:30 am - 8:30 am</p>
                                    <p className="text-left text-gray-500">Sat - Sun : 8:30 am - 8:30 pm</p>
                                </div>
                            </div>
                        </div>



                        {/* Second Row */}
                        <div className="col-lg-12 mt-4" align="center">
                            <div style={{ background: '#257fb4', padding: '10px' }}>
                                <h2 className='text-white fw-bold'>Dallas</h2>
                            </div>
                        </div>



                        {/* Frisco*/}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 cards">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/PgV7CDRKY1X3SukG6"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Frisco</a></h6>
                                    <p className="text-left text-gray-500 mb-1">2787 Preston Rd #1120,</p>
                                    <p className="text-left text-gray-500 mb-1">Frisco, TX 75034</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 214-980-6273</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>

                        {/*  Irving */}

                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/YiuV7K8kQ7eWCxWZ7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Irving</a></h6>
                                    <p className="text-left text-gray-500 mb-1">5451,N MAcArthur Blvd,</p>
                                    <p className="text-left text-gray-500 mb-1">Irving, TX 75038</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 214-662-6640</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 9:30 am - 9:00 pm
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*  Plano */}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/e3pfou837uxksXs36"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Plano</a></h6>
                                    <p className="text-left text-gray-500 mb-1">6205 Coit Rd suit 130,</p>
                                    <p className="text-left text-gray-500 mb-1">Plano,</p>
                                    <p className="text-left text-gray-500 mb-1">TX 75024</p>
                                    <p className="text-left text-gray-500 mb-1">United States</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 214-770-8509</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:30 a.m - 9:00 p.m</p>
                                </div>
                            </div>
                        </div>

                        {/* Eldorado */}


                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/NPRTY37QTy5ggbRa8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Eldorado</a></h6>
                                    <p className="text-left text-gray-500 mb-1">14399 Eldorado Pkwy,</p>
                                    <p className="text-left text-gray-500 mb-1">Suite #150,</p>
                                    <p className="text-left text-gray-500 mb-1">Frisco, TX 75035</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 972-292-9379</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10.00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>




                        {/* Atlanta */}

                        <div className="col-lg-12 mt-4" align="center">
                            <div style={{ background: '#257fb4', padding: '10px' }}>
                                <h2 className='text-white fw-bold'>Atlanta</h2>
                            </div>
                        </div>

                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/CuuxjBWvM8fDJZXE6"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Alpharetta </a></h6>
                                    <p className="text-left text-gray-500 mb-1">390 Cumming St,</p>
                                    <p className="text-left text-gray-500 mb-1">Alpharetta, GA 30004</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 404-844-8337.</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/dagLvQywe9C5Qgfw5"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Suwanee</a></h6>
                                    <p className="text-left text-gray-500 mb-1">3230 Caliber St,</p>
                                    <p className="text-left text-gray-500 mb-1">Suite A-105, GA 30024</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 678-282-4401</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>


                        {/* Chicago */}
                        <div className="col-lg-12 mt-4" align="center">
                            <div style={{ background: '#257fb4', padding: '10px' }}>
                                <h2 className='text-white fw-bold'>Chicago</h2>
                            </div>
                        </div>


                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/N6j7kJUamURF5iQW8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Schaumburg</a></h6>
                                    <p className="text-left text-gray-500 mb-1">821, W, Higgins Rd,</p>
                                    <p className="text-left text-gray-500 mb-1">Schaumburg, IL 60195</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 224-520-8564</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 8:00 pm</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 mt-4'>
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/dwWBxVnE5evgxiTa7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Naperville</a></h6>
                                    <p className="text-left text-gray-500 mb-1">1568 W OGDEN AVE, Suite-102</p>
                                    <p className="text-left text-gray-500 mb-1">Naperville, IL 60540</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 224-277-1223</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 8:00 pm</p>
                                </div>
                            </div>
                        </div>


                        {/* Virginia */}
                        <div className="col-lg-12 mt-4" align="center">
                            <div style={{ background: '#257fb4', padding: '10px' }}>
                                <h2 className='text-white fw-bold'>Virginia</h2>
                            </div>
                        </div>

                        {/*  Chantilly*/}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 cards">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/76H3ugx7LmZQneLp6"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Chantilly</a></h6>
                                    <p className="text-left text-gray-500 mb-1">13943 Metrotech Drive,</p>
                                    <p className="text-left text-gray-500 mb-1">Next to Lotte Plaza Market Chantilly, VA 20151</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 540-329-9174 & 202-993-0159</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>

                        {/* Ashburn */}
                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 cards">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/vGYVzGPRTytDcf199"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">
                                            Ashburn</a></h6>
                                    <p className="text-left text-gray-500 mb-1">located at 43930,</p>
                                    <p className="text-left text-gray-500 mb-1">Farmwell Hunt Plaza,</p>
                                    <p className="text-left text-gray-500 mb-1">Suite 120, Ashburn, VA 20147</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 571-510-3194</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>
                        


                    <div className="col-lg-12 mt-4" align="center">
                        <div style={{ background: '#257fb4', padding: '10px' }}>
                            <h2 className='text-white fw-bold'>Arizona
                            </h2>
                        </div>
                    </div>

                    <div className="col-lg-3 mt-4">
                        <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 cards">
                            <div className="card-img mb-4">
                                <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                    <a href="https://maps.app.goo.gl/KuSt65i6toJkbiU59"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-700 fw-bold text-decoration-none">
                                        Chandler</a></h6>
                                <p className="text-left text-gray-500 mb-1">1445 South Arizona Ave, Suite 9,</p>
                                <p className="text-left text-gray-500 mb-1">Santan South Shopping center Opposite to Patel Brothers,</p>
                                <p className="text-left text-gray-500 mb-1">Chandler, AZ 85286</p>
                                <p className="text-left text-gray-500 mb-1">Ph: 480-899-5714</p>
                                <p className="text-left text-gray-500">Mon- Fri : 10:00 am - 9:00 pm</p>
                            </div>
                        </div>
                    </div>


                    {/*  Boston */}


                    <div className="col-lg-12 mt-4" align="center">
                        <div style={{ background: '#257fb4', padding: '10px' }}>
                            <h2 className='text-white fw-bold'>Boston</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-4">
                        <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                            <div className="card-img mb-4">
                                <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                    <a href="https://maps.app.goo.gl/Bk4T127b3yFwJHtC9"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-700 fw-bold text-decoration-none">
                                        Boston</a></h6>
                                <p className="text-left text-gray-500 mb-1">578 Boston Turnpike Unit 2, Shrewsbury,</p>
                                <p className="text-left text-gray-500 mb-1">MA 01545</p>
                                <p className="text-left text-gray-500 mb-1">Ph: 774-314-1528 & 508-330-0235</p>
                                <p className="text-left text-gray-500">Monday - Sunday :10:00 am - 9:00 pm</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-12 mt-4" align="center">
                        <div style={{ background: '#257fb4', padding: '10px' }}>
                            <h2 className='text-white fw-bold'>California</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-4">
                        <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                            <div className="card-img mb-4">
                                <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                    <a href="https://maps.app.goo.gl/LFrLdTpLq8QE57AN6"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-700 fw-bold text-decoration-none">
                                        California(CA)</a></h6>
                                <p className="text-left text-gray-500 mb-1">5115 Mowry Ave, Fremont,</p>
                                <p className="text-left text-gray-500 mb-1">CA 94538</p>
                                <p className="text-left text-gray-500 mb-1">United States</p>
                                <p className="text-left text-gray-500">Monday - Sunday :10:00 am - 9:00 pm</p>
                                <p className="text-left text-gray-500 mb-1">Ph: +1 510 598-4397</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-4">
                        <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                            <div className="card-img mb-4">
                                <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                    <a href="https://maps.app.goo.gl/g7sDFWkw2MGsuNt27"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-700 fw-bold text-decoration-none">
                                        California(CA)</a></h6>
                                <p className="text-left text-gray-500 mb-1">18021,Pioneer Blvd, Artesia,</p>
                                <p className="text-left text-gray-500 mb-1">CA 90701,</p>
                                <p className="text-left text-gray-500 mb-1">United States</p>
                                <p className="text-left text-gray-500 mb-1">Ph: +1 562-202-4085</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 mt-4" align="center">
                        <div style={{ background: '#257fb4', padding: '10px' }}>
                            <h2 className='text-white fw-bold'>North Carolina</h2>
                        </div>
                    </div>


                        <div className="col-lg-3 mt-4">
                            <div className="cat-card bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 cards">
                                <div className="card-img mb-4">
                                    <h6 className="text-left text-xl font-semibold text-gray-700 mb-2">
                                        <a href="https://maps.app.goo.gl/ekBKq9Ne9thWAEVs5"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-700 fw-bold text-decoration-none">North Carolina</a></h6>
                                    <p className="text-left text-gray-500 mb-1">3735 Davis Dr Suite 109, </p>
                                    <p className="text-left text-gray-500 mb-1">Morrisville,</p>
                                    <p className="text-left text-gray-500 mb-1">NC 27560, United States</p>
                                    <p className="text-left text-gray-500 mb-1">Ph: 9192340534</p>
                                    <p className="text-left text-gray-500">Mon - Sun : 10:00 am - 9:00 pm</p>
                                </div>
                            </div>
                        </div>

                </div>
            </div>


        </section >

            <Footer />
        </>
    )
}

export default Location;
