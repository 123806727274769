import React, { useEffect, useState } from "react";
import { useLocation} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Flower from './flower';


const App = () => {

    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [orderId, setOrderId] = useState('');

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };
    
    useEffect(() => {
        const category = getQueryParam('token');
        setOrderId(category);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            
            setLoader(false);
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const payNow = () => {
        const paymentData = JSON.parse(sessionStorage.getItem('paymentData')) || [];
        if(paymentData.id){
            if(paymentData.id === orderId){
                window.location = paymentData.url;
            }else{
                alert('Payment token expired!');
                window.location = '/checkout';
            }
        }else{
            alert('Payment token expired!');
            window.location = '/checkout';
        }
    };

    const cancelOrder = () => {
        sessionStorage.removeItem('paymentData');
        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('poojaItems');
        window.location = '/';
    };
    
    

    return (
        

        <>
        
        {loader && <Flower /> }
            <Header />

            {/* breadcrumb */}

            <div class="sticky-header-next-sec  ec-breadcrumb section-space-mb">

                <div class="container">

                    <div class="row">

                        <div class="col-12">

                            <div class="row ec_breadcrumb_inner">

                                <div class="col-md-6 col-sm-12">

                                    <h2 class="ec-breadcrumb-title">{orderId}</h2>

                                </div>

                                <div class="col-md-6 col-sm-12">

                                    <ul class="ec-breadcrumb-list">

                                        <li class="ec-breadcrumb-item"><a href="/">Home</a></li>

                                        <li class="ec-breadcrumb-item active">Payment Process</li>

                                    </ul>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* end of the breadcrumb */}


            <section class="ec-page-content section-space-p">
                <div class="container">

                    <div className="row">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                            <div style={{ textAlign: 'center' }}>
                                You're cancelled the payment.<br />
                                <button class="btn btn-primary mt-3" style={{marginRight: '10px'}} onClick={payNow}>Pay Now</button>
                                <button class="btn btn-secondary mt-3" onClick={cancelOrder}>Cancel the Order</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>




<Footer/>


        </>
    )
}
export default App;