import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [customer, setCustomer] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const navigate = useNavigate();
  const location=useLocation();
  //console.log(customer);

  const handleLogin = async (e) => {
    e.preventDefault();
  
    if (!email || !password) {
      setError("Please fill in both fields");
      return;
    }
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Please enter a valid email");
      return;
    }
  
    try {
      setError("");
  
      const response = await axios.post("https://api.vivekflowers.com/api/cust-login", {
        email,
        password,
      });
  
      if (response.status === 200) {
        const customerData = response.data.cust_id;
        setCustomer(customerData);
        //console.log("customer data",customerData);
        sessionStorage.setItem("VVKuserId", response.data.cust_id);
  
        setToastMessage(`Welcome, ${response.data.first_name}! Login Sucessfully!`);
        setShowToast(true);
  
        const redirectTo = location.state?.from?.pathname || "/";
        navigate(redirectTo);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response?.data?.message || "An unexpected error occurred. Please try again later.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };
  

  return (
    <>
      <Header />

      {/* Breadcrumb */}
      <div className="sticky-header-next-sec ec-breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row ec_breadcrumb_inner">
                <div className="col-md-6 col-sm-12">
                  <h2 className="ec-breadcrumb-title">Login</h2>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul className="ec-breadcrumb-list">
                    <li className="ec-breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="ec-breadcrumb-item active">Login</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login */}
      <section className="ec-page-content section-space-p">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title">
                <h2 className="ec-title">Sign In</h2>
              </div>
            </div>

            <div className="ec-login-wrapper">
              <div className="ec-login-container">
                <div className="ec-login-form">
                  <form id="login-form" onSubmit={handleLogin}>
                    <span className="ec-login-wrap">
                      <label htmlFor="username">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        id="username"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </span>

                    <span className="ec-login-wrap">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </span>

                    <span className="ec-login-wrap ec-login-fp">
                      <label>
                        <a href="#">Forgot Password?</a>
                      </label>
                    </span>

                    {error && (
                      <div
                        className="error-message"
                        style={{ color: "red", marginBottom: "10px" }}
                      >
                        {error}
                      </div>
                    )}


                    <span className="ec-login-wrap ec-login-btn">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        style={{ borderRadius: "10px", width: "100%" }}
                      >
                        Sign In
                      </button>

                      <p className="or-divider">OR</p>

                      <p className="register-now">
                        Don't have an account?{" "}
                        <a href="/sign-up">Register Now</a>
                      </p>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="login_toast" class="login_toast">
        <div class="desc">
          {showToast && <div className="login_toast">{toastMessage}</div>}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Login;
