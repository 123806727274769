import React from "react";


const Footer = () => {

    return (

        <>

            {/* start footer */}

            <footer class="ec-footer">

                <div class="footer-container">

                    <div class="footer-top section-space-footer-p">

                        <div class="container">

                            <div class="row">

                                <div class="col-md-12 col-lg-3 ec-footer-cat">

                                    <div class="ec-footer-widget">

                                        <h4 class="ec-footer-heading">Quick Links

                                        </h4>

                                        <div class="ec-footer-links ">

                                            <ul class="align-items-center">
                                                <li class="ec-footer-link"><a href="/sign-in">Login</a></li>
                                                <li class="ec-footer-link"><a href="/sign-up">Register</a></li>
                                                <li class="ec-footer-link"><a href="/my-orders">Orders</a></li>
                                                <li class="ec-footer-link"><a href="/">Tracking</a></li>
                                                <li class="ec-footer-link"><a href="/">Sitemap</a></li>
                                            </ul>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-md-12 col-lg-3 ec-footer-info">

                                    <div class="ec-footer-widget">

                                        <h4 class="ec-footer-heading">Products</h4>

                                        <div class="ec-footer-links">

                                            <ul class="align-items-center">

                                                <li class="ec-footer-link"><a href="/">Flowers & Garlands</a></li>
                                                <li class="ec-footer-link"><a href="/">Pooja Items</a></li>
                                                <li class="ec-footer-link"><a href="/">Occational Items</a></li>
                                                <li class="ec-footer-link"><a href="/">Contact us</a></li>
                                                <li class="ec-footer-link"><a href="/">Sitemap</a></li>
                                            </ul>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-md-12 col-lg-3 ec-footer-account">

                                    <div class="ec-footer-widget">

                                        <h4 class="ec-footer-heading">Our Company</h4>

                                        <div class="ec-footer-links">

                                            <ul class="align-items-center">

                                                <li class="ec-footer-link"><a href="/">Delivery</a></li>

                                                <li class="ec-footer-link"><a href="/">Privacy Policy</a></li>

                                                <li class="ec-footer-link"><a href="/">Terms &  Conditions</a></li>

                                                <li class="ec-footer-link"><a href="/">About us</a></li>

                                                <li class="ec-footer-link"><a href="/">Secure Payment</a>

                                                </li>

                                            </ul>

                                        </div>

                                    </div>

                                </div>

                               

                                <div class="col-md-12 col-lg-3 ec-footer-cont-social">

                                 

                                        <div class="ec-footer-widget">

                                        <h4 class="ec-footer-heading marg-b-0 s-head">Contact Us</h4>

                                            <div class="ec-footer-links">

                                                <ul class="align-items-center">

                                                    <li class="ec-footer-link ec-foo-location">

                                                        <a> <i class="fi fi-rr-marker"></i> Vivekflowers, 1734, Unit 6, Oak Tree Road, Edison, New Jersey, US- 08820</a>

                                                    </li>

                                                    <li class="ec-footer-link ec-foo-call"><a href="tel:+18482005992"> <i class="fi-rr-phone-call"></i> +1 848 200 5992</a></li>



                                                    <li class="ec-footer-link ec-foo-mail"><a href="mailto:vivek@vivekflowers.com"> <i class="fi fi-rr-envelope"></i> vivek@vivekflowers.com</a>


                                                    </li>

                                                    

                                                </ul>

                                            </div>

                                        </div>

                                   

                                    <div class="ec-footer-social mt-4">

                                        <div class="ec-footer-widget">

                                            

                                            <div class="ec-footer-links">

                                                <ul class="align-items-center">

                                                    <li class="ec-footer-link"><a href="/"><i class="ecicon eci-instagram" aria-hidden="true"></i></a></li>

                                                    
                                                    <li class="ec-footer-link"><a href="/"><i class="ecicon eci-twitter-square" aria-hidden="true"></i></a></li>
                                                        
                                                   
                                                    <li class="ec-footer-link"><a href="/"><i  class="ecicon eci-facebook-square" aria-hidden="true"></i></a></li>
                                                       
                                                    
                                                    <li class="ec-footer-link"><a href="/"><i  class="ecicon eci-linkedin-square" aria-hidden="true"></i></a></li>
                                                       
                                                    
                                                </ul>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="footer-bottom">

                        <div class="container">

                            <div class="row">

                                {/* Footer payment */}
                                <div class="footer-bottom-right">

                                    <div class="footer-bottom-payment d-flex justify-content-center">

                                        <div class="payment-link">

                                            <img src="assets/images/icons/payment.png" alt="" />

                                        </div>

                                    </div>

                                </div>

                                {/* Footer payment  */}

                                {/* Footer Copyright Start  */}

                                <div class="footer-copy">

                                    <div class="footer-bottom-copy">

                                        <div class="ec-copy">Copyright © <span>{new Date().getFullYear()}</span> Vivek Flowers, All Rights Reserved. Powered by <a class="site-name" href="https://sninfoserv.com" target="_blank">SN Infoserv</a></div>
                                           
                                            
                                    </div>

                                </div>

                                {/* Footer Copyright End */}

                            </div>

                        </div>

                    </div>

                </div>

            </footer>

            {/* end of the footer */}

        </>
    )
}
export default Footer;