import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/signup";
import Cart from "./pages/cart";
import WishList from "./pages/wishlist";
import SingleProduct from './pages/singleProduct';
import ProductCategories from "./pages/category";
import CheckOut from "./pages/checkout";
import Flower from "./pages/flower";
import Test from "./pages/test";
import PCancelled from "./pages/paymentCancelled";
import PProcess from "./pages/paymentProcess";
import Orders from "./pages/orders";
import Locations from "./pages/location";
import Customer from "./pages/customer";
import Payment from "./pages/payment";

const App = () => {

  return (
    <div>
      <Router>

        <div>
          
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/sign-in" element={<Login/>}/>
            <Route path="/sign-up" element={<SignUp/>}/>
            <Route path="/category" element={<ProductCategories/>}/>
            <Route path="/cart" element={<Cart/>}/>
            <Route path="/wishlist" element={<WishList/>}/>
            <Route path="/product" element={<SingleProduct/>}/>
            <Route path="/checkout" element={<CheckOut/>}/>
            <Route path="/flower" element={<Flower/>}/>
            <Route path="/payment-cancelled" element={<PCancelled />}/>
            <Route path="/payment-process" element={<PProcess />}/>
            <Route path="/my-orders" element={<Orders />}/>
            <Route path="/locations" element={<Locations />}/>
            <Route path="/test" element={<Test />}/>
            <Route path="/payment" element={<Payment />}/>
            <Route path="/customer" element={<Customer />}/>
            
          </Routes>
          
        </div>
      </Router>

    </div>
  )

}

export default App;