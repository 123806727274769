import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Flower from './flower';
import {useLocation, useNavigate } from "react-router-dom";


const SingleProduct = () => {
  const [productId, setProductId] = useState('');
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);
  const [singleProduct, setSingleProduct] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [gst, setGST] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
      const intervalId = setInterval(() => {
          setLoader(false);
      }, 1000);
      return () => clearInterval(intervalId);
  }, []);

  
// console.log("selected variation",selectedVariation)
  const getCart = () => {
    const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
    setCartItems(cartItems);

    let total = 0;
    cartItems.forEach(item => {
      total += Number(item.price) * Number(item.qty)

    });

    setTotal(total);
    const gst = (Number(total) * 18) / 100;
    setGST(gst);
  };
  const handleVariationChange = (e) => {
    const variationId = e.target.value; 
    const selected = singleProduct.variations.find(
      (variation) => variation.variation_id === parseInt(variationId) 
    );
  
    if (selected) {
      setSelectedVariation(selected); 
    }
  };

  const handleAddToWishlist = async (productId, variationId) => {

    const cust_id = sessionStorage.getItem('VVKuserId'); 
    

    if (!cust_id) {
        
        navigate("/sign-in", { state: { from: location } });
        return;
    }

    

    try {
        const payload = {
            cust_id,
            product_id: productId,
            variation_id: variationId || null, 
        };

        const response = await axios.post('https://api.vivekflowers.com/api/wishlist', payload);

        if (response.status === 201) {
            alert('Product added to wishlist successfully!');
        } else {
            alert(response.data?.message || 'Failed to add product to wishlist.');
        }
    } catch (error) {
        console.error('Error adding product to wishlist:', error.message);
        alert('Failed to add product to wishlist. Please try again later.');
    }
};

  const addCart = () => {

    if(singleProduct.category_id === 2 && ( singleProduct.subcategory_id === 1 || singleProduct.subcategory_id === 2 ) ){

        const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const existingItemIndex = existingCartItems.findIndex(item => item.productId === productId);

        if (existingItemIndex !== -1) {

          const newQty = Number(existingCartItems[existingItemIndex].qty) + quantity;
          if (!isNaN(newQty) && newQty > 0) {
            existingCartItems[existingItemIndex].qty = newQty;
          } else {
            console.log("Invalid quantity");
          }
        } else {

          const newItem = {
            productId: productId,
            qty: quantity,
            price: selectedVariation?.variation_amount || singleProduct.amount,
            name: selectedVariation?.variation_name || singleProduct.productName,
            img: singleProduct.image_url,
            weight: selectedVariation?.weight || singleProduct.weight,
            // iceWeight: selectedVariation?.weightWithIce|| singleProduct.weightWithIce

          };

          existingCartItems.push(newItem);
        }

        sessionStorage.setItem('poojaItems', JSON.stringify(existingCartItems));
    }else{

      const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const existingItemIndex = existingCartItems.findIndex(item => item.productId === productId);

        if (existingItemIndex !== -1) {

          const newQty = Number(existingCartItems[existingItemIndex].qty) + quantity;
          if (!isNaN(newQty) && newQty > 0) {
            existingCartItems[existingItemIndex].qty = newQty;
          } else {
            console.log("Invalid quantity");
          }
        } else {

          const newItem = {
            productId: productId,
            qty: quantity,
            price: selectedVariation?.variation_amount || singleProduct.amount,
            name: selectedVariation?.variation_name || singleProduct.productName,
            img: singleProduct.image_url,
            weight: selectedVariation?.weight || singleProduct.weight,
            iceWeight: selectedVariation?.weightWithIce|| singleProduct.weightWithIce
          };

          existingCartItems.push(newItem);
        }

        sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));

    }
        getCart();

    const addToCartToast = document.getElementById("addtocart_toast");
        
    //addToCartToast.classList.remove("show");

    const productName = singleProduct.productName;
    const spanElement = addToCartToast.querySelector(".desc span");
    spanElement.textContent = productName;

    addToCartToast.classList.add("show");

    setTimeout(() => {
        addToCartToast.classList.remove("show");
    }, 3000);

  };


  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  const fetchProduct = async (productId) => {
    try {
      const response = await axios.get(
        `https://api.vivekflowers.com/api/products/variations-visibility?id=${productId}`
      );
      setSingleProduct(response.data);
      if (response.data.variations && response.data.variations.length > 0) {
        setSelectedVariation(null); 
      }
     // console.log("Single Product Data:", response.data);
      fetchRelatedProducts(productId);
    } catch (error) {
      console.error("Error fetching product:", error.message);
    }
  };

  const fetchRelatedProducts = async (productId) => {
    try {
      // Assuming your API endpoint allows you to pass productId to get related products
      const response = await axios.get(`https://api.vivekflowers.com/api/products/related?id=${productId}`);
      if (response.data) {
        setProducts(response.data.related_products);
        //console.log("related", response.data.related_products)
      }
    } catch (error) {
      console.error("Error fetching related products:", error.message);
    }
  };

  // const fetchProducts = async (cat_id) => {
  //   try {
  //     const response = await axios.get(`https://api.vivekflowers.com/api/products-with-variations/subcategory?name=${cat_id}`);
  //     if (response.data) {
  //       const updatedProducts = response.data.products.map((product) => ({
  //         ...product, quantity: 1,
  //       }));
  //       setProducts(updatedProducts);

  //     }
  //   } catch (error) {
  //     console.error("Error fetching products:", error.message);
  //   }
  // };

  const handleIncreaseQty = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleDecreaseQty = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  // const handleAddToCart = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://api.vivekflowers.com/cart",
  //       { productId, quantity },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         }
  //       }
  //     );
  //     console.log("Product added to cart:", response.data);
  //   } catch (error) {
  //     console.error("Error adding to cart:", error.message);
  //   }
  // };

  useEffect(() => {
    const id = getQueryParam("id");
    if (id) {
      setProductId(id);
      fetchProduct(id);
      getCart();
    }
  }, []);




  return (
    <>{loader && <Flower /> }
    <div className="shop_page">
      <Header />

      {/* Breadcrumb */}
      <div className="sticky-header-next-sec ec-breadcrumb section-space-mb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row ec_breadcrumb_inner">
                <div className="col-md-6 col-sm-12">
                  <h2 className="ec-breadcrumb-title">{singleProduct?.productName}</h2>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul className="ec-breadcrumb-list">
                    <li className="ec-breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="ec-breadcrumb-item active">{singleProduct?.productName}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Product Details */}
      <section className="ec-page-content section-space-p">
        <div className="container">
          <div className="row">
            <div className="ec-pro-rightside ec-common-rightside col-lg-12 col-md-12">
              <div className="single-pro-block">
                <div className="single-pro-inner">
                  <div className="row">
                    {singleProduct ? (
                      <>
                        <div className="single-pro-img single-pro-img-no-sidebar">
                          <div className="single-product-cover">
                            {singleProduct.images && singleProduct.images.length > 0 ? (
                              singleProduct.images.map((image, index) => (
                                <div key={index} className="single-slide zoom-image-hover">
                                  <img
                                    src={`https://api.vivekflowers.com/${singleProduct.image_url}`}
                                    alt={singleProduct.productName}
                                    className="img-fluid img-responsive"
                                  />
                                </div>
                              ))
                            ) : (
                              <div className="single-slide zoom-image-hover">
                                <img
                                  src={`https://api.vivekflowers.com/${singleProduct.image_url}`}
                                  alt={singleProduct.productName}
                                  className="img-fluid img-responsive"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="single-pro-desc single-pro-desc-no-sidebar">
                          <div className="single-pro-content" >
                            <h5 className="ec-single-title">{singleProduct.productName}</h5>
                            <div className="ec-single-desc">
                              <p>{singleProduct.description}</p>
                              <p className="ec-single-desc">{singleProduct.shortdescription}</p>
                            </div>


                           {singleProduct.amount && parseFloat(singleProduct.amount) > 0 ? <>
                            <div className="ec-single-price-stoke">
                              <div className="ec-single-price">
                              <span className="new-price">${selectedVariation?.variation_amount || singleProduct.amount}</span>
                              </div>
                            </div>
                            {/* Variation Dropdown */}
                              {singleProduct?.variations?.length > 0 && (
                                <div className="variation-dropdown ">
                                       <select
                                        id="variation-select"
                                        value={selectedVariation?.variation_id || ''}
                                        onChange={handleVariationChange}
                                        className="form-control border border-primary m-3"
                                      >
                            <option  value="" disabled>Select Variation</option>   
                            {singleProduct.variations.map((variation) => (
                            <option key={variation.variation_id} value={variation.variation_id}>
                            {variation.variation_name}
                            </option>
                              ))}
                              </select>
                             </div>
                             )}
      


                            <div class="ec-single-qty" >

                              <div className="qty-plus-minus">

                                <button className="qty-btn" onClick={handleDecreaseQty}>
                                  -
                                </button>
                                <input
                                  className="qty-input"
                                  type="text"
                                  value={quantity}
                                  readOnly
                                />
                                <button className="qty-btn" onClick={handleIncreaseQty}>
                                  +
                                </button>
                              </div>

                              
                              <div class="ec-single-cart">
                              {singleProduct.variations&& singleProduct.variations.length>0 ? (
                                selectedVariation ? (
                                  
                                <button class="btn btn-primary" onClick={addCart}>Add To Cart</button>
                                 
                                ):(
                                  <p className="text-muted" style={{fontSize:'15px',margin:'10px'}}>Select a variation to add to cart</p>
                                )
                              ):(
                                
                                <button class="btn btn-primary" onClick={addCart}>Add To Cart</button>                            
                                
                              )                              
                              }
                               </div>


                              
                              <div class="ec-single-wishlist">
                                <a class="ec-btn-group wishlist" onClick={() => handleAddToWishlist(singleProduct.product_p_id, singleProduct.selectedVariation?.variation_id)} title="Wishlist"><i
                                  class="fi-rr-heart"></i></a>
                              </div>

                            </div>

                            </>
                            :
                            <div className="ec-single-price-stoke">
                              <div className="ec-single-price">
                                <span className="new-price text-danger">Call us 848 200 5992</span>
                              </div>
                            </div>
                            }

                          </div>
                        </div>
                      </>
                    ) : (
                      <p>Loading product details...</p>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section >











      {/* Related Products */}

      <section className="section ec-releted-product section-space-p">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title">
                <h2 className="ec-bg-title">Related products</h2>
                <h2 className="ec-title">Related products</h2>
                <p className="sub-title">Browse The Collection of Top Products</p>
              </div>
            </div>
          </div>
          <div className="row margin-minus-b-30">
            {products.slice(0, 4).map((product, index) => (
              <div className="col-xl-3 col-lg-3  mb-6 pro-gl-content" key={index}>
                <div className="ec-product-inner">
                  <div className="ec-pro-image-outer">
                    <div className="ec-pro-image">
                      <Link onClick={() => (window.location.href = `/product?id=${product.p_id}`)} className="image">
                        <img
                          className="main-image"
                          src={`https://api.vivekflowers.com/${product.image_url}`}
                          alt={product.name}
                        />
                        {/* <img
                        className="hover-image"
                        src={`https://167.88.42.252/vivek-api/${product.hoverImageUrl}`} // Assuming hover image base URL
                        alt={product.name}
                      /> */}
                      </Link>
                      <div className="ec-pro-actions">
                        <button title="Add To Cart" className="add-to-cart">
                          <i className="fi-rr-shopping-basket"></i> Add To Cart
                        </button>
                        <Link className="ec-btn-group wishlist" title="Wishlist">
                          <i className="fi-rr-heart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="ec-pro-content">
                    <h5 className="ec-pro-title">
                    <Link onClick={() => (window.location.href = `/product?id=${product.p_id}`)}>{product.productName || 'Product Name'}</Link>
                    </h5>
                    <span className="ec-price">
                      <span className="new-price">${product.amount || '0.00'}</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div id="addtocart_toast" class="addtocart_toast">
        <div class="desc"><span></span> Added To Cart</div>
      </div>
      <div id="wishlist_toast" class="wishlist_toast">
        <div class="desc">Added To Wishlist</div>
      </div>
      {/* Related Products end  */}

      <Footer />
    </div >
    </>
  );
};

export default SingleProduct;
