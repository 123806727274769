import React, { useEffect, useState } from "react";
import './flower.css';

const flower = () => {

return(

	
	<div class="content">
	  
	  <div class="load-wrapp">
		<div class="load-3">
		  <div class="line"></div>
		  <div class="line"></div>
		  <div class="line"></div>
		</div>
	  </div>
	  
	<div class="clear"></div>
	</div>
  
)
}
export default flower;