import React, { useEffect, useState } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Flower from './flower';


const Cart = () => {

    const [cartItems, setCartItems] = useState([]);
    const [poojaItems, setPoojaItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [gst, setGST] = useState(0);
    const [poojaTotal, setPoojaTotal] = useState(0);
    const [poojaGst, setPoojaGST] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(true);
        
    const getCart = () => {
                const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
                setCartItems(cartItems);

                const poojaItems = JSON.parse(sessionStorage.getItem('poojaItems')) || [];
                setPoojaItems(poojaItems);
        
                let total = 0;
                cartItems.forEach(item => {
                    total += Number(item.price) * Number(item.qty)
                    
                });

                let poojaTotal = 0;
                poojaItems.forEach(item => {
                    poojaTotal += Number(item.price) * Number(item.qty)
                    
                });
        
                setTotal(total);
                const gst = (Number(total)*18)/100;
                setGST(gst);

                setPoojaTotal(poojaTotal);
                const poojaGst = (Number(poojaTotal)*18)/100;
                setPoojaGST(poojaGst);
    };
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            getCart();
            setLoader(false);
        }, 500);
        return () => clearInterval(intervalId);
    }, []);
    
        
    const removeItem = (id, name) => {
		const updatedCartItems = cartItems.filter(item => item.productId !== id || item.name !== name);
		sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
		getCart();
	};

    const toggleCoupon = () => {
        setIsOpen(!isOpen);
    };

    // const changeQty = (cart, option, id, name) => {
        
    //     const cartKey = cart === 'pooja' ? 'poojaItems' : 'cartItems';
    //     const existingCartItems = JSON.parse(sessionStorage.getItem(cartKey)) || [];
    //     const existingItemIndex = existingCartItems.findIndex(item => item.productId === id && item.name === name);
        
    //     if (existingItemIndex !== -1) {
    //         const newQty = existingCartItems[existingItemIndex].qty + (option === 'plus' ? 1 : -1);
    //         if (newQty > 0) {
    //             existingCartItems[existingItemIndex].qty = newQty;
    //         }
    //         sessionStorage.setItem(cartKey, JSON.stringify(existingCartItems));
    //         getCart();
    //     }
    // };
    const changeQty = (cart, option, id, name, value = null) => {
        const cartKey = cart === 'pooja' ? 'poojaItems' : 'cartItems';
        const existingCartItems = JSON.parse(sessionStorage.getItem(cartKey)) || [];
        const existingItemIndex = existingCartItems.findIndex(item => item.productId === id && item.name === name);
    
        if (existingItemIndex !== -1) {
            let newQty;
            if (value !== null) {
                newQty = parseInt(value) || 1; 
            } else {
                newQty = existingCartItems[existingItemIndex].qty + (option === 'plus' ? 1 : -1);
            }
    
            if (newQty > 0) {
                existingCartItems[existingItemIndex].qty = newQty;
                sessionStorage.setItem(cartKey, JSON.stringify(existingCartItems));
                getCart();
            }
        }
    };

   // console.log(poojaItems);

    return (
        

        <>
        
        {loader && <Flower /> }
            <Header />

            {/* breadcrumb */}

            <div class="sticky-header-next-sec  ec-breadcrumb section-space-mb">

                <div class="container">

                    <div class="row">

                        <div class="col-12">

                            <div class="row ec_breadcrumb_inner">

                                <div class="col-md-6 col-sm-12">

                                    <h2 class="ec-breadcrumb-title">Cart</h2>

                                </div>

                                <div class="col-md-6 col-sm-12">

                                    <ul class="ec-breadcrumb-list">

                                        <li class="ec-breadcrumb-item"><a href="/">Home</a></li>

                                        <li class="ec-breadcrumb-item active">Cart</li>

                                    </ul>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* end of the breadcrumb */}


            <section class="ec-page-content section-space-p">
                <div class="container">

                {cartItems.length > 0 ?

                    <div class="row">
                        <div class="ec-cart-leftside col-lg-8 col-md-12 ">
                            <div class="ec-cart-content">
                                <div class="ec-cart-inner">
                                    <div class="row">
                                            <div class="table-content cart-table-content">
                                            {cartItems.length > 0 || poojaItems.length > 0 ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Price</th>
                                                            <th width="20%" style={{textAlign: 'center'}}>Quantity</th>
                                                            <th>Total</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {cartItems.map((items, index) => 
                                                        <tr key={index}>
                                                            <td data-label="Product" class="ec-cart-pro-name">
                                                                <a href="">
                                                                    <img class="ec-cart-pro-img mr-4" src={`https://api.vivekflowers.com/${items.img}`} alt="" />
                                                                    {items.name}
                                                                </a>
                                                            </td>
                                                            <td data-label="Price" class="ec-cart-pro-price"><span
                                                                class="amount">${Number(items.price).toFixed(2)}</span></td>
                                                           <td data-label="Quantity" class="ec-cart-pro-qty" style={{ textAlign: 'center' }}>
                                                                 <div class="cart-qty-plus-minus">
                                                                 <button class="btn btn-secondary" onClick={() => changeQty('cart', 'minus', items.productId, items.name)}>-</button>
                                                                 <input class="cart-plus-minus" type="text" name="cartqtybutton" value={items.qty} min="1"
                                                                      onChange={(e) => changeQty('cart', null, items.productId, items.name, e.target.value)}
                                                                 />
                                                                 <button class="btn btn-success" onClick={() => changeQty('cart', 'plus', items.productId, items.name)}>+</button>
                                                                 </div>
                                                            </td>
                                                            <td data-label="Total" class="ec-cart-pro-subtotal">${Number(items.price * items.qty).toFixed(2)}</td>
                                                            <td data-label="Remove" class="ec-cart-pro-remove">
                                                                <a href="#" onClick={() => removeItem(items.productId, items.name)}><i class="ecicon eci-trash-o"></i></a>
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {poojaItems.map((items, index) => 
                                                        <tr key={index}>
                                                            <td data-label="Product" class="ec-cart-pro-name">
                                                                <a href=""><img class="ec-cart-pro-img mr-4" src={`https://api.vivekflowers.com/${items.img}`} alt="" />
                                                                    {items.name}
                                                                </a>
                                                            </td>
                                                            <td data-label="Price" class="ec-cart-pro-price"><span
                                                                class="amount">${Number(items.price).toFixed(2)}</span></td>
                                                            <td data-label="Quantity" class="ec-cart-pro-qty" style={{ textAlign: 'center' }}>
                                                                 <div class="cart-qty-plus-minus">
                                                                 <button class="btn btn-secondary" onClick={() => changeQty('cart', 'minus', items.productId, items.name)}>-</button>
                                                                 <input class="cart-plus-minus" type="text" name="cartqtybutton" value={items.qty} min="1"
                                                                      onChange={(e) => changeQty('cart', null, items.productId, items.name, e.target.value)}
                                                                 />
                                                                 <button class="btn btn-success" onClick={() => changeQty('cart', 'plus', items.productId, items.name)}>+</button>
                                                                 </div>
                                                            </td>
                                                            <td data-label="Total" class="ec-cart-pro-subtotal">${Number(items.price * items.qty).toFixed(2)}</td>
                                                            <td data-label="Remove" class="ec-cart-pro-remove">
                                                                <a href="#" onClick={() => removeItem(items.productId, items.name)}><i class="ecicon eci-trash-o"></i></a>
                                                            </td>
                                                        </tr>
                                                    )}

                                                       
                                                       
                                                    </tbody>
                                                </table>
                                                :
                                                <></>
                                            }
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="ec-cart-update-bottom">
                                                        <a href="/">Continue Shopping</a>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ec-cart-rightside col-lg-4 col-md-12">
                            <div class="ec-sidebar-wrap">
                                <div class="ec-sidebar-block">
                                    <div class="ec-sb-title">
                                        <h3 class="ec-sidebar-title">Summary</h3>
                                    </div>
                                    {/* <div class="ec-sb-block-content">
                                        <h4 class="ec-ship-title">Estimate Shipping</h4>
                                        <div class="ec-cart-form">
                                            <p>Enter your destination to get a shipping estimate</p>
                                            <form action="#" method="post">
                                                <span class="ec-cart-wrap">
                                                    <label>Country *</label>
                                                    <span class="ec-cart-select-inner">
                                                        <select name="ec_cart_country" id="ec-cart-select-country"
                                                            class="ec-cart-select">
                                                            <option selected="" disabled="">United States</option>
                                                            <option value="1">Edison</option>
                                                            <option value="2">Jersey City</option>
                                                            <option value="3">East Windsor</option>
                                                            <option value="4">Piscataway</option>
                                                            <option value="5">South Brunswick</option>
                                                        </select>
                                                    </span>
                                                </span>

                                                <span class="ec-cart-wrap">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" name="postalcode" placeholder="Zip/Postal Code"/>
                                                </span>
                                            </form>
                                        </div>
                                    </div> */}

                                    <div class="ec-sb-block-content">
                                        <div class="ec-cart-summary-bottom">
                                            <div class="ec-cart-summary">
                                                <div>
                                                    <span class="text-left">Sub-Total</span>
                                                    <span class="text-right">${Number(total+poojaTotal).toFixed(2)}</span>
                                                </div>
                                                <div>
                                                    <span class="text-left">Coupon Discount</span>
                                                    <span class="text-right">$00.00</span>
                                                </div>
                                                <div>
                                                    <span class="text-left">Have Coupon Code ?</span>
                                                    <span class="text-right"><a class="ec-cart-coupan" onClick={toggleCoupon} >Apply Coupon</a></span>
                                                </div>
                                                <div class="ec-cart-coupan-content" style={{display: isOpen ? 'block' : 'none'}}>
                                                    <form class="ec-cart-coupan-form" >
                                                        <input class="ec-coupan" type="text" required=""
                                                            placeholder="Enter Your Coupan Code" name="ec-coupan" value=""/>
                                                            <button class="ec-coupan-btn button btn-primary" type="submit" name="Apply" value="">Apply</button>
                                                    </form>
                                                </div>
                                                <div class="ec-cart-summary-total">
                                                    <span class="text-left">Total Amount</span>
                                                    <span class="text-right">${Number(total+poojaTotal).toFixed(2)}</span>
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="/checkout" style={{color: 'white'}}><button type="button" class="btn btn-primary w-100 mt-4">Check Out</button></a>
                        </div>
                    </div>

                :
                    <div className="row">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                            <div style={{ textAlign: 'center' }}>
                                Your cart is Empty, Please add items!<br />
                                <a href="/" class="btn btn-primary mt-3">Shop Now</a>
                            </div>
                        </div>
                    </div>

                }

                </div>
            </section>




<Footer/>


        </>
    )
}
export default Cart;