import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.css';


const Header = () => {

    const userId = sessionStorage.getItem('VVKuserId') || 0;

    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isSubCat, setIsSubcat] = useState(false);
    const [isCart, setIsCart] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState([]);

    const [cartItems, setCartItems] = useState([]);
    const [poojaItems, setPoojaItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [poojaTotal, setPoojaTotal] = useState(0);
    //const [gst, setGST] = useState(0);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    // const handleSearchChange = (e) => {
    //     setSearchQuery(e.target.value);
    // };

    // const handleSearchSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.get('https://api.vivekflowers.com/api/search-products', {
    //             params: { search: searchQuery }
    //         });

    //         if (response.data) {
    //             const updatedProducts = response.data.products.map((product) => ({
    //                 ...product,
    //                 quantity: 1,
    //                 selectedVariation: null,
    //             }));
    //             setSearchedProducts(updatedProducts);
    //             navigate(`/category?id=${searchQuery}`, { state: { products: updatedProducts } });
    //         }



    //     } catch (error) {
    //         console.error('Error fetching products:', error);
    //     }
    // };

    
    const handleSearchChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        

        if (query.length >= 3) { 
            try {
                const response = await axios.get("https://api.vivekflowers.com/api/search-products", {
                    params: { search: query },
                });
                setSearchedProducts(response.data.products);
                setShowDropdown(true);
            } catch (error) {
                console.error("Error fetching products:", error);
                setSearchedProducts([]);
                setShowDropdown(false);
            }
        } else {
            setSearchedProducts([]);
            setShowDropdown(false);
        }
    };

    const handleSelectProduct = async (productName) => {
        setSearchQuery(productName); // Update search query with selected product name
        setShowDropdown(false); // Hide dropdown
    
        try {
            const response = await axios.get("https://api.vivekflowers.com/api/search-products", {
                params: { search: productName }, // Fetch product details again
            });
    
            if (response.data.products.length > 0) {
                navigate(`/category?id=${productName}`, { state: { products: response.data.products } });
            } else {
                console.error("No products found for selected item.");
            }
        } catch (error) {
            console.error("Error fetching selected product:", error);
        }
    };

    const getCart = () => {

        const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const updatedCartItems = cartItems.filter(item => item.qty && item.qty > 0);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);

        const poojaItems = JSON.parse(sessionStorage.getItem('poojaItems')) || [];
        const updatedPoojaItems = poojaItems.filter(item => item.qty && item.qty > 0);
        sessionStorage.setItem('poojaItems', JSON.stringify(updatedPoojaItems));
        setPoojaItems(updatedPoojaItems);

        

        let total = 0;
        cartItems.forEach(item => {
            total += Number(item.price) * Number(item.qty)
        });

        let poojatotal = 0;
        poojaItems.forEach(item => {
            poojatotal += Number(item.price) * Number(item.qty)
        });

        setTotal(total);
        const gst = (Number(total) * 18) / 100;
        //setGST(gst);

        setPoojaTotal(poojatotal);
        const poojaGst = (Number(poojatotal) * 18) / 100;
        //setGST(gst);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            getCart();
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);


    const removeItem = (id, name) => {
        const updatedCartItems = cartItems.filter(item => item.productId !== id || item.name !== name);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        getCart();
    };

    const removePoojaItem = (id, name) => {
        const updatedCartItems = poojaItems.filter(item => item.productId !== id || item.name !== name);
        sessionStorage.setItem('poojaItems', JSON.stringify(updatedCartItems));
        getCart();
    };






    const toggleMenu = () => {
        setIsMobileOpen(!isMobileOpen);
    }

    const toggleCart = () => {
        setIsCart(!isCart);
    }

    useEffect(() => {
        fetch("https://api.vivekflowers.com/api/categories")
            .then((response) => response.json())
            .then((data) => setCategories(data))
            .catch((error) => console.error("Error Fetching Categories", error));

    }, []);

    // Handle Category Selection
    const handleSubCategories = (categoryId) => {
        setselectedCategory(categoryId);
        setIsSubcat(true);
        const category = categories.find(cat => cat.id === categoryId);
        setSubCategories(category ? category.subcategories : []);
    };


    // Function to Split the SubCategories
    const splitSubCategories = (arr, size) => {
        const chunk = [];
        for (let i = 0; i < arr.length; i += size) {
            chunk.push(arr.slice(i, i + size));
        }
        return chunk;
    }


    const logout = () => {
        sessionStorage.removeItem('VVKuserId');
    };



    return (

        <>
            {/* header */}

            <header className="ec-header">

                <div className="header-top">

                    <div className="container">

                    </div>

                </div>

            </header>

            {/* end of the header-top */}

            {/* Navigation */}

            <div className="col d-lg-none ">

                <div className="ec-header-bottons">

                    {/* user authentication */}

                    <div className="ec-header-user dropdown">

                        <button className="dropdown-toggle" data-bs-toggle="dropdown"><i className="fi-rr-user"></i></button>

                        <ul className="dropdown-menu dropdown-menu-right">
                            {userId && parseInt(userId) > 0 ?
                                <>
                                    {/* <li><a className="dropdown-item" href="/my-orders">Orders</a></li> */}
                                    <li><a className="dropdown-item" href={`/customer?id=${userId}`}>Account</a></li>
                                    <li><a className="dropdown-item" onClick={logout}>Logout</a></li>
                                </>
                                :
                                <>
                                    <li><a className="dropdown-item" href="/sign-up">Register</a></li>
                                    <li><a className="dropdown-item" href="/sign-in">Login</a></li>
                                </>
                            }
                        </ul>

                    </div>
                    {/* end of the user authentication */}

                    {/* wishlist */}

                    <a href="/wishlist" className="ec-header-btn ec-header-wishlist">

                        <div className="header-icon"><i className="fi-rr-heart"></i></div>

                        {/* <span className="ec-header-count">4</span> */}

                    </a>

                    {/* end of the wishlist */}

                    {/* cart */}

                    <a onClick={toggleCart} className="ec-header-btn ec-side-toggle" style={{ marginRight: '10px' }}>

                        <div className="header-icon"><i className="fi-rr-shopping-basket"></i></div>

                        <span className="ec-header-count ec-cart-count cart-count-lable">{cartItems.length + poojaItems.length}</span>

                    </a>

                    {/* end of the cart */}

                    <button onClick={toggleMenu} className="ec-header-btn ec-side-toggle d-lg-none">

                        <i className="fi-rr-menu-burger"></i>

                    </button>

                </div>

            </div>



            <div className="ec-header-bottom d-none d-lg-block p-0">

                <div className="container position-relative">

                    <div className="row">

                        <div className="ec-flex">

                            {/* Logo */}

                            <div className="align-self-center">

                                <div className="header-logo">

                                    <a href="/"><img src="../assets/images/logo/viveks_logo.png" alt="Site Logo" width="200px" height="64px" />

                                        <img className="dark-logo" src="../assets/images/logo/viveks_logo.png" alt="Site Logo" style={{ display: 'none' }} /></a>

                                </div>

                            </div>

                            {/* end of the Logo */}

                            {/* Search  */}

                             <div className="align-self-center">
                                <div className="header-search">
                                   <form className="ec-btn-group-form" >
                                       <input
                                           className="form-control"
                                           placeholder="Enter Your Product Name..."
                                           type="text"
                                           value={searchQuery}
                                           onChange={handleSearchChange}
                                           onFocus={() => setShowDropdown(searchedProducts.length > 0)}
                                        />
                                    <button className="submit" type="submit">
                                    <i className="fi-rr-search"></i>
                                    </button>
                                  </form>

                                    {showDropdown && searchedProducts.length > 0 && 
                                    <div class="search-box">
                                        <ul>
                                            {searchedProducts.map((product, index) => (
                                                <li key={index} onClick={() => handleSelectProduct(product.productName)}>
                                                    {product.productName}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    }

                                {/* Display dropdown if there are suggestions */}
                                {/* {showDropdown && searchedProducts.length > 0 && (
                                <ul className="search-suggestions">
                                  {searchedProducts.map((product, index) => (
                                  <li key={index} onClick={() => handleSelectProduct(product.productName)}>
                                   {product.productName}
                                  </li>
                                  ))}
                                </ul>
                                )} */}
                               </div>
                              </div>

                            {/* end of the search */}


                            {/* user authentication */}

                            <div className="align-self-center">

                                <div className="ec-header-bottons">

                                    <div className="ec-header-user dropdown">

                                        <button className="dropdown-toggle" data-bs-toggle="dropdown"><i className="fi-rr-user"></i></button>


                                        <ul className="dropdown-menu dropdown-menu-right">

                                            {userId && parseInt(userId) > 0 ?
                                                <>
                                                    {/* <li><a className="dropdown-item" href="/my-orders">Orders</a></li> */}
                                                    <li><a className="dropdown-item" href={`/customer?id=${userId}`}>Account</a></li>
                                                    <li><a className="dropdown-item" onClick={logout}>Logout</a></li>
                                                </>
                                                :
                                                <>
                                                    <li><a className="dropdown-item" href="/sign-up">Register</a></li>
                                                    <li><a className="dropdown-item" href="/sign-in">Login</a></li>
                                                </>
                                            }

                                        </ul>

                                    </div>

                                    <a href="/wishlist" className="ec-header-btn ec-header-wishlist">

                                        <div className="header-icon"><i className="fi-rr-heart"></i></div>

                                        {/* <span className="ec-header-count">3</span> */}

                                    </a>

                                    <a onClick={toggleCart} className="ec-header-btn ec-side-toggle">

                                        <div className="header-icon"><i className="fi-rr-shopping-basket"></i></div>

                                        <span className="ec-header-count ec-cart-count cart-count-lable">{cartItems.length + poojaItems.length}</span>

                                    </a>

                                    {/* <a href="#ec-mobile-menu" className="ec-header-btn ec-side-toggle d-lg-none">

                                        <i className="fi-rr-menu-burger"></i>

                                    </a> */}

                                    <button onClick={toggleMenu}


                                        className="ec-header-btn ec-side-toggle d-lg-none"
                                    >
                                        <i className="fi-rr-menu-burger"></i>
                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="ec-header-bottom d-lg-none">

                <div className="container position-relative">

                    <div className="row ">

                        <div className="col">

                            <div className="header-logo">

                                <a href="/">

                                    <img src="../assets/images/logo/viveks_logo.png" alt="Site Logo" />

                                    <img className="dark-logo" src="../assets/images/logo/viveks_logo.png" alt="Site Logo" style={{ display: 'none' }} />

                                </a>

                            </div>

                        </div>

                        <div className="col">

                            <div className="header-search">

                                {/* <form className="ec-btn-group-form" onSubmit={handleSearchSubmit}> */}
                                <form className="ec-btn-group-form" >

                                    <input className="form-control" placeholder="Enter Your Product Name..." type="text" 
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    onFocus={() => setShowDropdown(searchedProducts.length > 0)}
                                     />

                                    <button className="submit" type="submit"><i className="fi-rr-search"></i></button>

                                </form>

                                {showDropdown && searchedProducts.length > 0 && 
                                    <div class="search-box">
                                        <ul>
                                            {searchedProducts.map((product, index) => (
                                                <li key={index} onClick={() => handleSelectProduct(product.productName)}>
                                                    {product.productName}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }

                            </div>

                        </div>

                    </div>

                </div>

            </div>


            <div id="ec-main-menu-desk" className="d-none d-lg-block sticky-nav">

                <div className="container position-relative">

                    <div className="row">

                        <div className="col-md-12 align-self-center">

                            <div className="ec-main-menu">

                                <ul>

                                    <li><a href="/">Home</a></li>

                                    {/* Wedding */}
                                    {categories.map((category) => (

                                        <li key={category.id} className="dropdown position-static">

                                            <a onMouseEnter={() => handleSubCategories(category.id)} className="category-button">{category.name}</a>

                                            <ul className="mega-menu d-block">

                                                <li className="d-flex">

                                                    {splitSubCategories(subcategories, 5).map((subchunk, index) => (
                                                        <ul key={index} className="d-block">
                                                            {subchunk.map((subcategory) => (
                                                                <li key={subcategory.id}>
                                                                    <a href={`/category?id=${subcategory.name}`}>
                                                                        {subcategory.name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ))}



                                                </li>

                                                <li>

                                                    <ul className="ec-main-banner w-100">

                                                        <li><a className="p-0" href="/category?id=Rose%20Wedding%20Garlands"><img className="img-responsive" src="assets/images/menu-banner/1.jpg" alt="" /></a></li>

                                                        <li><a className="p-0" href="/category?id=Loose%20Flowers"><img className="img-responsive" src="assets/images/menu-banner/2.jpg" alt="" /></a></li>

                                                        {/* <li><a className="p-0" href="/category?id=Temple%20garlands"><img className="img-responsive" src="assets/images/menu-banner/3.jpg" alt="" /></a></li> */}

                                                        <li><a className="p-0" href="/category?id=Hand%20Ties%20/%20Bouquets"><img className="img-responsive" src="assets/images/menu-banner/4.jpg" alt="" /></a></li>

                                                    </ul>

                                                </li>


                                            </ul>

                                        </li>
                                    ))}



                                    <li><a href="/locations">Locations</a></li>



                                </ul>



                            </div>

                        </div>

                    </div>

                </div>



            </div>

            {/* mobile menu */}

            {isMobileOpen && (
                <div id="ec-mobile-menu" class="ec-side-cart ec-mobile-menu ec-open">

                    <div class="ec-menu-title">

                        <span class="menu_title">Menu</span>

                        <button class="ec-close" onClick={toggleMenu}>×</button>

                    </div>

                    <div class="ec-menu-inner">

                        <div class="ec-menu-content">

                            <ul>

                                <li><a href="/">Home</a></li>

                                {categories.map((category) => (

                                    <li key={category.id} className={selectedCategory === category.id && isSubCat ? 'active' : ''}>
                                        <span class="menu-toggle"></span>
                                        <a onClick={() => handleSubCategories(category.id)}>{category.name}</a>

                                        {selectedCategory === category.id && (

                                            <ul class="sub-menu" style={{ display: selectedCategory === category.id && isSubCat ? 'block' : 'none' }}>

                                                {subcategories.map((subcategory) => (

                                                    <li key={subcategory.id}>

                                                        <a href={`/category?id=${subcategory.name}`}>{subcategory.name}</a>

                                                    </li>

                                                ))}
                                            </ul>
                                        )}

                                    </li>

                                ))}

                                <li><a href="/locations">Locations</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            )}
            <div class="ec-side-cart-overlay" style={{ display: isMobileOpen || isCart ? 'block' : 'none' }} onClick={isCart? toggleCart : toggleMenu}></div>

            <div id="ec-side-cart" class={`ec-side-cart ${isCart && 'ec-open'}`}>
                <div class="ec-cart-inner">
                    <div class="ec-cart-top">
                        <div class="ec-cart-title">
                            <span class="cart_title">My Cart</span>
                            <button class="ec-close" onClick={toggleCart}>×</button>
                        </div>

                        {cartItems.length > 0 || poojaItems.length > 0 ?
                            <ul class="eccart-pro-items">
                                {cartItems.map((items, index) =>
                                    <li key={index}>
                                        <a href="/" class="sidecart_pro_img"><img src={`https://api.vivekflowers.com/${items.img}`} alt="product" /></a>
                                        <div class="ec-pro-content">
                                            <a href="/" class="cart_pro_title" style={{ marginBottom: '15px' }}>{items.name}</a>
                                            <span><span>${Number(items.price).toFixed(2)}</span> x {items.qty}</span>
                                            <span class="cart-price"><span>${Number(items.price * items.qty).toFixed(2)}</span></span>
                                            <a class="remove" onClick={() => removeItem(items.productId, items.name)}>x</a>

                                        </div>
                                    </li>
                                )}

                                {poojaItems.map((items, index) =>
                                    <li key={index}>
                                        <a href="/" class="sidecart_pro_img"><img src={`https://api.vivekflowers.com/${items.img}`} alt="product" /></a>
                                        <div class="ec-pro-content">
                                            <a href="/" class="cart_pro_title" style={{ marginBottom: '15px' }}>{items.name}</a>
                                            <span><span>${Number(items.price).toFixed(2)}</span> x {items.qty}</span>
                                            <span class="cart-price"><span>${Number(items.price * items.qty).toFixed(2)}</span></span>
                                            <a class="remove" onClick={() => removePoojaItem(items.productId, items.name)}>x</a>

                                        </div>
                                    </li>
                                )}
                            </ul>
                            :
                            <div align="center" style={{ marginTop: '30vh' }}>
                                <p>Your Cart is Empty please add items!</p>
                            </div>
                        }
                    </div>
                    <div class="ec-cart-bottom">
                        <div class="cart-sub-total">
                            <table class="table cart-table">
                                <tbody>
                                    <tr>
                                        <td class="text-left">Sub Total :</td>
                                        <td class="text-right">${Number(Number(total) + Number(poojaTotal)).toFixed(2)}</td>
                                    </tr>
                                    {/* <tr>
                                        <td class="text-left">VAT (18%) :</td>
                                        <td class="text-right">${Number(gst).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Grand Total :</td>
                                        <td class="text-right primary-color">${Number(total + gst ).toFixed(2)}</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div class="cart_btn">
                            <a href="/cart" class="btn btn-primary">View Cart</a>
                            <a href="/checkout" class="btn btn-secondary">Checkout</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;